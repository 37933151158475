import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';
import Loader from 'components/Loader';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import EnhancedTableHead from './TableHeader';
import ToolbarTable from './ToolbarTable';
import { table } from 'constants/common';
import {
	removeAccents,
	formatd3,
	parseCLP,
	fuzzy,
} from 'globalFunctions/globalFunctions';
import { useTableStyles } from './styles';
import TablePaginationActions from './Pagination';

export const concatSelectedRows = (selected, row) => {
	const selectedIndex = selected.indexOf(row);
	let newSelected = [];

	if (selectedIndex === -1) {
		newSelected = newSelected.concat(selected, row);
	} else if (selectedIndex === 0) {
		newSelected = newSelected.concat(selected.slice(1));
	} else if (selectedIndex === selected.length - 1) {
		newSelected = newSelected.concat(selected.slice(0, -1));
	} else if (selectedIndex > 0) {
		newSelected = newSelected.concat(
			selected.slice(0, selectedIndex),
			selected.slice(selectedIndex + 1)
		);
	}

	return newSelected;
};

export default function CustomTable({
	searchbarPlaceholder = 'Buscar...',
	data = [],
	loading,
	selectedRows = [],
	onSelectedChange,
	actions = [],
	xls,
	columns,
	idToFind,
	filterBy,
	cellActions,
	title,
	onRowClick,
	iconActionButtons,
	onVisibleCheckbox = () => true,
	onChangeRowsPerPage,
	onChangePage,
	totalItems,
	searchOptions,
	emptyTableData,
	extraAction,
	pagination,
	onAllSelect,
}) {
	const [searchedValue, setSearchedValue] = useState('');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState(null);
	const [isAllSelected, setIsAllSelected] = useState(false);
	const classes = useTableStyles();

	useEffect(() => {
		if (selectedRows.length === 0) {
			setIsAllSelected(false);
			!!onAllSelect && onAllSelect(false, []);
		} else if (selectedRows.length > 0) {
			const discarted = data
				.filter(
					item => !selectedRows.some(row => item[idToFind] === row[idToFind])
				)
				.map(contact => ({ contactId: contact.contactId, name: contact.name }));

			!!onAllSelect && onAllSelect(true, discarted);
		}
	}, [selectedRows]);

	const handleChangePage = (_, newPage) => {
		setPage(newPage);
		onChangePage && onChangePage(newPage, rowsPerPage);
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		onChangeRowsPerPage &&
			onChangeRowsPerPage(parseInt(event.target.value, 10));
	};

	const filterData = data?.filter(n =>
		onVisibleCheckbox ? onVisibleCheckbox(n) : n
	);

	const handleClick = (_, row) => {
		const selectedRow = data.find(item => item[idToFind] === row[idToFind]);
		const newSelected = concatSelectedRows(selectedRows, selectedRow);

		setIsAllSelected(filterData.length === newSelected.length);
		onSelectedChange(newSelected);
	};

	const handleSelectAllClick = event => {
		onSelectedChange(event.target.checked ? filterData : []);
		setIsAllSelected(event.target.checked);
		!!onAllSelect && onAllSelect(event.target.checked, []);
	};

	const descendingComparator = (a, b, orderBy) => {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	};

	const handleRequestSort = (_, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const stableSort = (array, comparator) => {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map(el => el[0]);
	};

	const getComparator = (order, orderBy) => {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	};

	const handleSearchState = value =>
		setSearchedValue(() => {
			if (value === '' && !!searchOptions) {
				searchOptions.onCancel();
			}

			return value;
		});

	const handleClearSearch = () => {
		setSearchedValue('');
		!!searchOptions && searchOptions.onCancel();
	};
	const hasDataToSelectCheckBox =
		data?.filter(el => onVisibleCheckbox(el)).length >= 1;

	const items = totalItems ? totalItems : data.length;

	const handleIconActions = item =>
		typeof iconActionButtons === 'function'
			? iconActionButtons(item)
			: iconActionButtons;

	return (
		<>
			{(!loading && data?.length > 0) ||
			(!!searchOptions && searchedValue?.length > 0) ? (
				<ToolbarTable
					searchOptions={searchOptions}
					numSelected={selectedRows.length}
					numLeases={data.length}
					actions={actions}
					xls={xls}
					onSearchChange={handleSearchState}
					searchedValue={searchedValue}
					onClearSearch={handleClearSearch}
					title={title}
					searchbarPlaceholder={searchbarPlaceholder}
				/>
			) : null}
			<TableContainer component={Paper} className={classes.table}>
				<Table>
					<EnhancedTableHead
						columns={columns}
						rowCount={data.length}
						numSelected={selectedRows.length}
						isVisibleCheckAll={onSelectedChange && hasDataToSelectCheckBox}
						onSelectAllClick={handleSelectAllClick}
						isAllSelected={isAllSelected}
						loading={loading}
						onRequestSort={handleRequestSort}
						order={order}
						orderBy={orderBy}
					/>
					{loading ? null : (
						<TableBody>
							{stableSort(data, getComparator(order, orderBy))
								.filter(row =>
									searchedValue.length > 0 && !searchOptions
										? fuzzy(
												removeAccents(
													filterBy ? filterBy(row) : row.nombrePropiedad
												).toLowerCase(),
												searchedValue.toLowerCase()
										  )
										: true
								)
								.slice(
									!!pagination
										? (pagination.page - 1) * pagination.rowsPerPage
										: page * rowsPerPage,
									!!pagination
										? (pagination.page - 1) * pagination.rowsPerPage +
												pagination.rowsPerPage
										: page * rowsPerPage + rowsPerPage
								)
								.map((item, index) => {
									const isItemSelected =
										selectedRows.findIndex(
											row => row[idToFind] === item[idToFind]
										) !== -1;
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											key={index}
											aria-checked={isItemSelected}
											tabIndex={-1}
											selected={isItemSelected}
											className={classes.tableRow}
											onClick={e => onRowClick && onRowClick(e, item)}
										>
											{onSelectedChange && hasDataToSelectCheckBox && (
												<TableCell padding="checkbox">
													{onVisibleCheckbox(item) && (
														<Checkbox
															className={classes.selectCheckbox}
															onClick={e => e.stopPropagation()}
															onChange={event => handleClick(event, item)}
															checked={isItemSelected}
															inputProps={{ 'aria-labelledby': labelId }}
														/>
													)}
												</TableCell>
											)}

											{columns.map(({ align, render, field, type }, index) => (
												<TableCell key={index} align={align}>
													{!!render
														? type === 'number'
															? formatd3('entero', render(item))
															: type === 'CLP'
															? parseCLP(render(item))
															: render(item)
														: type === 'number'
														? formatd3('entero', item[field])
														: type === 'CLP'
														? parseCLP(item[field])
														: item[field]}
												</TableCell>
											))}
											{extraAction || cellActions || iconActionButtons ? (
												<TableCell align="right" className={classes.sticky}>
													{cellActions &&
														cellActions.map(
															({ onClick, color, text }, index) => (
																<Button
																	key={index}
																	color={color ? color : 'primary'}
																	className={classes.linkButton}
																	onClick={e => {
																		e.stopPropagation();
																		onClick(item);
																	}}
																>
																	{text}
																</Button>
															)
														)}
													{!!extraAction && extraAction(item)}
													{iconActionButtons && (
														<div
															style={{
																display: 'flex',
																flexDirection: 'row',
																justifyContent: 'flex-end',
															}}
														>
															{handleIconActions(item)
																.filter(e => e === 0 || e)
																.map(
																	(
																		{ icon, tooltip, onClick, ...buttonProps },
																		index
																	) => (
																		<Tooltip key={index} title={tooltip}>
																			<IconButton
																				size="small"
																				{...buttonProps}
																				onClick={e => {
																					if (!!onClick) {
																						e.stopPropagation();
																						!!onClick && onClick(item);
																					} else {
																						return undefined;
																					}
																				}}
																			>
																				{icon}
																			</IconButton>
																		</Tooltip>
																	)
																)}
														</div>
													)}
												</TableCell>
											) : null}
										</TableRow>
									);
								})}
						</TableBody>
					)}
				</Table>
				{items === 0 && !loading ? (
					<Grid
						justify="center"
						alignItems="center"
						className={classes.loaderContainer}
						container
					>
						<Grid item>
							{!!emptyTableData ? (
								emptyTableData()
							) : (
								<Typography>No hay datos</Typography>
							)}
						</Grid>
					</Grid>
				) : null}
				{loading && (
					<Grid
						container
						className={classes.loaderContainer}
						justify="center"
						alignItems="center"
					>
						<Grid item>
							<Loader />
						</Grid>
					</Grid>
				)}
			</TableContainer>
			{items > 0 && !loading ? (
				<TablePagination
					rowsPerPageOptions={table.ROWS_PER_PAGE_OPTIONS}
					labelDisplayedRows={table.labelDisplayedRows}
					labelRowsPerPage={table.LABEL_ROWS_PER_PAGE}
					component="div"
					count={items}
					rowsPerPage={!!pagination ? pagination.rowsPerPage : rowsPerPage}
					page={!!pagination ? pagination.page - 1 : page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
					className={classes.paginator}
					ActionsComponent={props => <TablePaginationActions {...props} />}
				/>
			) : null}
		</>
	);
}
