import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

import {
	dashboardLayoutRoutes,
	dashboardLayoutRoutesOwner,
	authLayoutRoutes,
	dashboardLayoutWithoutAccountRoutes,
} from './index';
import DashboardLayout from '../layouts/Dashboard';
import DashboardLayoutWithoutAccount from '../layouts/DashboardWithoutAccount';
import AuthLayout from '../layouts/Auth';
import Page404 from '../pages/Page404';
import StateContext from '../services/contextService';
import * as queries from './queries';
import Loading from '../loading/Loading';

import { roles } from 'constants/common';
import { manageSectionActions } from 'globalFunctions/permissions';

// TODO: Optimizar
const childRoutes = ({ Layout, routes, role }) =>
	routes
		.map(
			(
				{ children, path, component: Component, section, exact = true },
				index
			) =>
				children
					? children.map(
							({ path, component: Component }, index) =>
								manageSectionActions({
									section,
									role,
									actions: ['ver'],
								}) && (
									<Route
										key={index}
										path={path}
										exact={exact}
										render={props => (
											<Layout>
												<Component {...props} />
											</Layout>
										)}
									/>
								)
					  )
					: manageSectionActions({
							section,
							role,
							actions: ['ver'],
					  }) && (
							<Route
								key={index}
								path={path}
								exact={exact}
								render={props => (
									<Layout>
										<Component {...props} />
									</Layout>
								)}
							/>
					  )
		)
		.filter(route => route);

const Routes = props => {
	return (
		<Router>
			{props.authState === 'signedIn' && (
				<SignedInRoutes onStateChange={props.onStateChange} />
			)}
		</Router>
	);
};

export default Routes;

let context_object_not_extensible = { value: null, date: null };
const context_object = Object.create(context_object_not_extensible);

const SignedInRoutes = props => {
	const { contextStates, setContextStates } = useContext(StateContext);
	const [listAccounts] = useLazyQuery(queries.LIST_ACCOUNTS_NEW);
	const history = useHistory();

	const getUserRole = (data, userEmail) => {
		if (data.memberInfo?.role) return data.memberInfo?.role;
		if (data.accountInfo?.email === userEmail) return roles.admin.SUPER;
		return roles.COLLABORATOR;
	};

	useEffect(() => {
		Auth.currentAuthenticatedUser({ bypassCache: false })
			.then(user => {
				listAccounts({
					variables: { input: { email: user.attributes.email } },
					onCompleted: data => {
						context_object.onStateChange = props.onStateChange;
						context_object.memberEmail =
							data?.listAccountsNew.memberInfo?.memberEmail;
						context_object.currentUser = {
							attributes: user.attributes,
						};
						context_object.accountName =
							data?.listAccountsNew.accountInfo?.name;
						context_object.role = getUserRole(
							data.listAccountsNew,
							user.attributes.email
						);
						context_object.accountId =
							data?.listAccountsNew.accountInfo?.accountId;
						context_object.isAdmincuenta =
							data?.listAccountsNew.memberInfo?.role === roles.admin.RENTAPP
								? true
								: false;
						context_object.currentPeriod = new Date();
						context_object.currentProperty = null;
						context_object.currentLease = null;
						context_object.periodoAltaGestor = '05-01-2021';
						context_object.userGroup = getUserRole(
							data.listAccountsNew,
							user.attributes.email
						);
						// context_object.communicationEmail =
						// 	data?.listAccountsNew.accountInfo.communicationEmail;
						context_object.analyticUrl =
							data?.listAccountsNew.accountInfo?.analyticUrl;
						setContextStates(context_object);
					},
				});
			})
			.catch(err => console.log(err));
	}, []);

	const isRentappAdmin = contextStates?.isAdmincuenta;
	const hasAccountId = contextStates.accountId;

	useEffect(() => {
		const navigateRentapp = () => history.push('/rentapp-admin');
		const navigateIndex = () => history.push('/');
		if (isRentappAdmin) {
			if (!hasAccountId) {
				navigateRentapp();
			} else if (
				hasAccountId &&
				history.location.pathname === '/rentapp-admin'
			) {
				navigateIndex();
			}
		}
	}, [isRentappAdmin, history, hasAccountId]);

	if (!contextStates?.currentUser)
		return <Loading text={'Cargando datos usuario'} />;

	if (!!contextStates.accountId)
		return (
			<Switch>
				{contextStates.role === roles.OWNER
					? childRoutes({
							Layout: DashboardLayout,
							routes: dashboardLayoutRoutesOwner,
							role: contextStates.role,
					  })
					: childRoutes({
							Layout: DashboardLayout,
							routes: dashboardLayoutRoutes,
							role: contextStates.role,
					  })}
				{childRoutes({
					Layout: AuthLayout,
					routes: authLayoutRoutes,
					role: contextStates.role,
				})}
				<Route
					render={() => (
						<AuthLayout>
							<Page404 />
						</AuthLayout>
					)}
				/>
			</Switch>
		);
	else
		return (
			<Switch>
				{childRoutes({
					Layout: DashboardLayoutWithoutAccount,
					routes: dashboardLayoutWithoutAccountRoutes,
					role: contextStates.role,
				})}
				<Route
					render={() => (
						<AuthLayout>
							<Page404 />
						</AuthLayout>
					)}
				/>
			</Switch>
		);
};
