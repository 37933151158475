import React from 'react';
import { useSectionHeaderStyles } from './styles';
import { useHistory } from 'react-router-dom';
import Typography from 'components/Typography/Typography';
import Chip from 'components/Chip/Chip.jsx';
import Tooltip from 'components/Tooltip/Tooltip';
import Button from 'components/Button/Button';
import { ArrowBackIosRounded } from '@material-ui/icons';

const SectionHeader = ({
	backButton,
	title,
	subTitle,
	linkButtons,
	children,
}) => {
	const classes = useSectionHeaderStyles();
	const history = useHistory();

	return (
		<React.Fragment>
			<div className={classes.container}>
				<div className={classes.titleContainer}>
					{backButton && (
						<Button onClick={backButton} icon={<ArrowBackIosRounded />} />
					)}
					<Typography variant='h1' className={classes.title}>
						{title}
					</Typography>
					<Typography variant='h4' className={classes.subTitle}>
						{subTitle}
					</Typography>
				</div>
				{children && <div>{children}</div>}
				{linkButtons && (
					<div className={classes.buttons}>
						{linkButtons.map(
							(button, index) =>
								button && (
									<div key={index}>
										<Tooltip title={button.tooltip ?? ''} placement='top'>
											<div>
												<Chip
													icon={button.icon}
													onClick={() => {
														history.push(button.route);
													}}
													hovercolor='primary'
													label={button.name}
													size='small'
												/>
											</div>
										</Tooltip>
									</div>
								)
						)}
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

export default SectionHeader;
