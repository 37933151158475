import React from 'react';
import async from '../components/Async';
import {
  Activity,
  DollarSign,
  Home,
  Key,
  Layers,
  List,
  User,
  Users,
} from 'react-feather';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import InsertDriveFile from '@material-ui/icons/InsertDriveFileOutlined';
import {
  PaymentRounded as PaymentIcon,
  AccountBalance as AccountBalanceIcon,
  WifiRounded as WifiIcon,
  BarChart as ChartIcon,
} from '@material-ui/icons/';
import { useRouteMatch, Switch, Route } from 'react-router-dom';

// Auth components
// const ResetPassword = async(() => import("../authentication/ResetPassword"));

// Dashboards components
const Dashboard = async(() => import('../pages/pages/dashboard/index'));

//Owner Dashboard
const OwnerDashboard = async(() =>
  import('../pages/pages/dashboard/ownerDashboard/OwnerDashboard')
);

// Icons components
// const MaterialIcons = async(() => import("../static/icons/MaterialIcons"));
// const FeatherIcons = async(() => import("../static/icons/FeatherIcons"));

// Pages components
const Blank = async(() => import('../pages/Blank'));
const Page404 = async(() => import('../pages/Page404'));
const Page500 = async(() => import('../pages/Page500'));

/* CONTACTS */
const Contacts = async(() => import('../pages/pages/contacts/Contacts'));
const Owners = async(() => import('../pages/pages/contacts/owners/Owners'));
const LeasesContacts = async(() =>
  import('../pages/pages/contacts/leases/LeasesContacts')
);
const ProfileContact = async(() =>
  import('../pages/pages/contacts/ProfileContact')
);
const UpdateContact = async(() =>
  import('../pages/pages/contacts/UpdateContact')
);
const CreateContact = async(() =>
  import('../pages/pages/contacts/CreateContact')
);

/* CONDOS */
const Condos = async(() => import('../pages/pages/condos/Condos'));
const ProfileCondo = async(() => import('../pages/pages/condos/ProfileCondo'));
const CreateCondo = async(() =>
  import('../pages/pages/condos/CreateCondo/CreateCondo')
);
const UpdateCondo = async(() =>
  import('../pages/pages/condos/UpdateCondo/UpdateCondo')
);

/* PROPERTIES */
const Properties = async(() => import('../pages/pages/properties/Properties'));
const CreateProperty = async(() =>
  import('../pages/pages/properties/CreateProperty/CreateProperty.jsx')
);
const UpdateProperty = async(() =>
  import('../pages/pages/properties/UpdateProperty/UpdateProperty')
);
const ProfileProperty = async(() =>
  import('../pages/pages/properties/profileProperty/ProfileProperty')
);
const PropertiesByState = async(() =>
  import('../pages/pages/properties/byState')
);
const Vacancy = async(() => import('../pages/pages/properties/vacancy'));

/* ACCOUNT */
const CreateAccount = async(() => import('../pages/pages/account/NewAccount'));
const Profile = async(() => import('../pages/pages/account/Profile'));

/* MEMBERS */
const AccountUsers = async(() =>
  import('../pages/pages/account/membersAcounts/MembersTable')
);
const ProfileMember = async(() =>
  import('../pages/pages/account/membersAcounts/ProfileMember')
);
const UpdateMember = async(() =>
  import('../pages/pages/account/membersAcounts/UpdateMember')
);

/* LEASES */
const Leases = async(() => import('../pages/pages/leases/Leases/Leases'));
const ValidityLease = async(() =>
  import('../pages/pages/leases/validityLease')
);
const ByState = async(() => import('../pages/pages/leases/byState'));
const ContractManager = async(() =>
  import('../pages/pages/leases/ContractManager')
);
const CreateLease = async(() =>
  import('../pages/pages/leases/Sections/CreateLease/CreateLease')
);
const UpdateLease = async(() =>
  import('../pages/pages/leases/Sections/UpdateLease/UpdateLease')
);
const CreateLeaseCondition = async(() =>
  import(
    '../pages/pages/leases/Sections/CreateLeaseCondition/CreateLeaseCondition'
  )
);
const ProfileLease = async(() =>
  import('../pages/pages/leases/ProfileLease/ProfileLease')
);

// Contracts
const UploadContract = async(() =>
  import('../pages/pages/contracts/files-upload')
);

const ContractGenerator = async(() =>
  import('../pages/pages/contracts/contract-generator')
);

const UpdateContract = async(() =>
  import(
    '../pages/pages/contracts/contract-generator/components/UpdateContract/UpdateContract'
  )
);

/* ACCOUNTING */
const Cartola = async(() =>
  import('../pages/pages/accounting/cartolaIndividual/CartolaPropiedadWrapper')
);
const CartolaNew = async(() =>
  import('../pages/pages/accounting/cartolaIndividual2/CartolaPropiedadWrapper')
);
const CartolaPropiedadHistorica = async(() =>
  import('../pages/pages/accounting/cartolaHistorica/CartolaPropiedadHistorica')
);
const RecaudacionMasiva = async(() =>
  import('../pages/pages/accounting/recaudacionMasiva/RecaudacionMasiva')
);
const LiquidacionMasiva = async(() =>
  import('../pages/pages/accounting/liquidacionMasiva/LiquidacionMasiva')
);
const Transactions = async(() =>
  import('../pages/pages/accounting/transactions/Transactions')
);
const TransactionsNew = async(() =>
  import('../pages/pages/accounting/transactions/Transactions/TransactionsForm')
);
const ProfileTransaction = async(() =>
  import('../pages/pages/accounting/transactions/ProfileTransaction')
);
const UpdateTransaction = async(() =>
  import('../pages/pages/accounting/transactions/UpdateTransaction')
);
const LeaseStatics = async(() =>
  import(
    '../pages/pages/dashboard/recaudacionyliquidacion/RecaudacionyLiquidacion'
  )
);
const OwnerAccounting = async(() => import('../pages/pages/accounting/owner'));
const OwnerAccounting2 = async(() =>
  import('../pages/pages/accounting/owner2')
);
const OwnersAccounting = async(() =>
  import('../pages/pages/accounting/ownersAccounting/OwnersAccounting')
);
const BasicServices = async(() => import('../pages/pages/basic-services'));
const AccountingStatement = async(() =>
  import('../pages/pages/accounting/accountingStatement')
);

const LiquidationByState = async(() =>
  import('../pages/pages/accounting/liquidation-by-state')
);

const Analytics = async(() => import('../pages/pages/analytics/Analytics'));

// Documentation
const Changelog = async(() => import('../pages/pages/version/Changelog'));
const Management = async(() => import('../pages/pages/management'));

const OwnerInfo = async(() => import('../pages/pages/account/Owner'));
const ClosePeriod = async(() => import('../pages/pages/accounting/close'));

const presentationRoutes = {
  id: 'Presentation',
  path: '/',
  icon: <Activity />,
  component: Dashboard,
  children: null,
};

const presentationRoutesOwner = {
  id: 'Presentation',
  path: '/',
  icon: <Activity />,
  component: OwnerDashboard,
  children: null,
};

const presentationRoutesWithoutAccount = {
  id: 'Presentation',
  path: '/',
  icon: <Activity />,
  component: CreateAccount,
  children: null,
};

/* DASHBOARD */
const dashboardsRoutes = {
  id: 'Inicio',
  path: '/inicio',
  icon: <Activity />,
  containsHome: true,
  component: Dashboard,
};

const dashboardsRoutesOwner = {
  id: 'Inicio',
  path: '/inicio',
  icon: <Activity />,
  containsHome: true,
  component: OwnerDashboard,
};

/* CONTACTS */
const contactsRoutes = {
  id: 'Contactos',
  path: '/contacts',
  section: 'contacts',
  icon: <User />,
  component: Contacts,
  children: [
    {
      path: '/contacts/owners',
      name: 'Propietarios',
      component: Owners,
    },
    {
      path: '/contacts/leases',
      name: 'Arrendatarios',
      component: LeasesContacts,
    },
    {
      path: '/contacts/contacts',
      name: 'Todos',
      component: Contacts,
    },
  ],
};

const profileContactRoutes = {
  id: 'ProfileContact',
  path: '/contacts/:contactId',
  section: 'contacts',
  exact: false,
  component: ProfileContact,
  children: null,
};

const profileRoutes = {
  id: 'Profile',
  path: '/mi-cuenta',
  component: Profile,
  children: null,
};

const updateContactRoutes = {
  id: 'UpdateContact',
  path: '/contact/update/:contactId',
  section: 'contacts',
  icon: <User />,
  component: UpdateContact,
  children: null,
};

const createContactRoutes = {
  id: 'CreateContact',
  path: '/contacts/create',
  section: 'contacts',
  icon: <User />,
  component: CreateContact,
  children: null,
};

/* CONDOS */
const condosRoutes = {
  id: 'Condominios',
  path: '/condos',
  section: 'condominios',
  icon: <HomeWorkOutlinedIcon />,
  component: Condos,
  children: null,
};

const profileCondoRoutes = {
  id: 'ProfileCondo',
  path: '/condos/:condoId',
  section: 'condominios',
  exact: false,
  icon: <Layers />,
  component: ProfileCondo,
  children: null,
};

const createCondoRoutes = {
  id: 'CreateCondo',
  path: '/condos/create',
  section: 'condominios',
  icon: <Layers />,
  component: CreateCondo,
  children: null,
};

const updateCondoRoutes = {
  id: 'UpdateCondo',
  path: '/condo/update/:condoId',
  section: 'condominios',
  icon: <Layers />,
  component: UpdateCondo,
  children: null,
};

/* PROPERTIES */
const propertiesRoutes = {
  id: 'Propiedades',
  path: '/properties',
  section: 'propiedades',
  icon: <Home />,
  component: null,
  containsHome: true,
  children: [
    {
      path: '/properties',
      name: 'Buscador Propiedades',
      component: Properties,
    },
    {
      path: '/properties/by-state',
      name: 'Propiedades por Estado',
      component: PropertiesByState,
    },
    {
      path: '/properties/vacancy',
      name: 'Reporte de Vacancia',
      component: Vacancy,
    },
  ],
};

const profilePropertyRoutes = {
  id: 'ProfileProperty',
  path: '/properties/:propertyId',
  section: 'propiedades',
  icon: <Home />,
  exact: false,
  component: ProfileProperty,
  children: null,
};

const createPropertyRoutes = {
  id: 'CreateProperty',
  path: '/properties/create',
  section: 'propiedades',
  icon: <Home />,
  component: CreateProperty,
  children: null,
};

const updatePropertyRoutes = {
  id: 'UpdateProperty',
  path: '/properties/update/:propertyId',
  section: 'propiedades',
  icon: <Home />,
  component: UpdateProperty,
  children: null,
};

const corretajesRoutes = {
  id: 'Corretajes',
  path: '/corretajes',
  icon: <Home />,
  component: Profile,
  children: null,
};

/* LEASES */
const leasesRoutes = {
  id: 'Arriendos',
  path: '/leases',
  section: 'arriendos',
  icon: <Key />,
  containsHome: true,
  children: [
    {
      path: '/leases/buscador',
      name: 'Buscador Arriendos',
      component: Leases,
    },
    {
      path: '/leases/estado',
      name: 'Arriendos por Estado',
      component: ByState,
    },
    {
      path: '/leases/vigencia',
      name: 'Arriendos por Vencer',
      component: ValidityLease,
    },
  ],
};

const profileLeaseRoutes = {
  id: 'ProfileLease',
  path: '/leases/:leaseId',
  section: 'arriendos',
  icon: <Key />,
  exact: false,
  component: ProfileLease,
  children: null,
};

const createLeaseConditionRoutes = {
  id: 'CreateLeaseCondition',
  path: '/lease/new-condition/:leaseId',
  section: 'arriendos',
  icon: <Key />,
  component: () => <CreateLeaseCondition newCondition />,
  children: null,
};

const updateLeaseRoutes = {
  id: 'UpdateLease',
  path: '/leases/update/:leaseId',
  section: 'arriendos',
  icon: <Key />,
  component: UpdateLease,
  children: null,
};

const createLeaseRoutes = {
  id: 'CreateLease',
  path: '/leases/create',
  section: 'arriendos',
  icon: <Key />,
  component: CreateLease,
  children: null,
};

/* ACCOUNT */
const accountUsersRoutes = {
  id: 'AccountUsers',
  path: '/members',
  icon: <User />,
  component: AccountUsers,
  children: null,
};

const createAccountRoutes = {
  id: 'CreateAccount',
  path: '/member/create',
  section: 'adminUsers',
  icon: <User />,
  component: CreateAccount,
  children: null,
};

const newAccountRoutes = {
  id: 'RentapAdmin',
  path: '/rentapp-admin',
  section: 'adminUsers',
  icon: <User />,
  component: Management,
  children: null,
};

const profileMembersRoutes = {
  id: 'ProfileMember',
  path: '/members/:id',
  section: 'adminUsers',
  icon: <User />,
  component: ProfileMember,
  children: null,
};

const updateMembersRoutes = {
  id: 'ProfileMember',
  path: '/members/update/:id',
  section: 'adminUsers',
  icon: <User />,
  component: UpdateMember,
  children: null,
};

// /* ACCOUNTING */
// const accountingRoutes = {
// 	id: 'Contabilidad Propiedad',
// 	path: '/accounting',
// 	section: 'contabilidad',
// 	icon: <AccountBalanceIcon />,
// 	containsHome: true,
// 	children: [
// 		{
// 			path: '/accounting/cartolaIndividual2',
// 			name: 'Contabilidad Período',
// 			component: CartolaNew,
// 		},
// 		{
// 			path: '/accounting/cartolaHistorica',
// 			name: 'Transacciones',
// 			component: CartolaPropiedadHistorica,
// 		},
// 		{
// 			path: '/accounting/statements',
// 			name: 'Estado Contable',
// 			component: AccountingStatement,
// 		},
// 	],
// 	component: null,
// };

const ownerAccountingRoutesOwner = {
  id: 'Contabilidad',
  section: 'contabilidad',
  icon: <DollarSign />,
  containsHome: true,
  children: [
    {
      path: '/accounting/owner',
      name: 'Contabilidad Periodo',
      component: OwnerAccounting,
    },
    {
      path: '/accounting/collections',
      name: 'Recaudación Masiva',
      component: () => <RecaudacionMasiva isPay />,
    },
    {
      path: '/accounting/liquidation-by-state',
      name: 'Liquidación Masiva',
      component: LiquidationByState,
    },
    {
      path: '/accounting/close-accounting',
      name: 'Cierre de Periodo',
      component: ClosePeriod,
    },
  ],
};

const basicServicesRoutes = {
  id: 'Lectura de servicios',
  path: '/services',
  icon: <WifiIcon />,
  containsHome: true,
  component: BasicServices,
};

const accountingRoutesOwner = {
  id: 'Contabilidad',
  path: '/accounting',
  section: 'contabilidad',
  icon: <DollarSign />,
  containsHome: true,
  children: [
    {
      path: '/accounting/cartolaIndividual',
      name: 'Cartolas',
      component: Cartola,
    },
  ],
  component: null,
};

/*TRANSACTIONS*/

const profileTransactionRoutes = {
  id: 'ProfileTransaction',
  path: '/accounting/transactions/profile/:transactionId',
  section: 'contabilidad',

  icon: <DollarSign />,
  component: ProfileTransaction,
  children: null,
};

const updateTransactionRoutes = {
  id: 'UpdateTransaction',
  path: '/accounting/transactions/update/:transactionId',
  section: 'contabilidad',
  icon: <DollarSign />,
  component: UpdateTransaction,
  children: null,
};

/* MASS COLLECTION */
// const leasseRoutes = {
// 	id: 'Liquidación Masiva de Arriendos',
// 	path: '/recaudacion',
// 	icon: <PaymentIcon />,
// 	containsHome: true,
// 	children: [],
// 	component: null,
// };

const profileOwnerRoutes = {
  id: 'Perfil de usuario',
  path: '/profile',
  icon: <User />,
  component: ProfileContact,
  children: null,
};

// Contracts

const contractRoutes = {
  id: 'Contratos',
  path: '/contracts',
  icon: <InsertDriveFile />,
  containsHome: true,
  exact: false,
  children: [
    {
      path: '/contracts/upload-file',
      name: 'Subir PDF ',
      component: UploadContract,
    },
    {
      path: '/contracts/generator',
      name: 'Generador',
      component: ContractGenerator,
    },
  ],
  component: null,
};

const updateContractRoute = {
  id: 'UpdateContractRoute',
  path: '/contracts/:contractId/:entityType/:entityId/update',
  icon: null,
  component: UpdateContract,
  children: null,
};

// This route is not visisble in the sidebar
const privateRoutes = {
  id: 'Private',
  path: '/private',
  component: Blank,
  children: null,
};

const authRoutes = {
  id: 'Auth',
  path: '/auth',
  icon: <Users />,
  children: [
    {
      path: '/auth/404',
      name: '404 Page',
      component: Page404,
    },
    {
      path: '/auth/500',
      name: '500 Page',
      component: Page500,
    },
  ],
  component: null,
};

// const mantencionesRoutes = {
//   id: "Mantenciones",
//   path: "/mantenciones",
//   icon: <Sliders />,
//   containsHome: true,
//   children: [
//     {
//       path: "/mantenciones/requerimientos",
//       name: "Requerimientos",
//       component: null
//     },
//     {
//       path: "/mantenciones/inspeccion",
//       name: "Inspección de Propiedad",
//       component: null
//     }
//   ],
//   component: null
// };

// const iconsRoutes = {
//   id: "Icons",
//   path: "/icons",
//   icon: <Heart />,
//   children: [
//     {
//       path: "/icons/material-icons",
//       name: "Material Icons",
//       component: MaterialIcons
//     },
//     {
//       path: "/icons/feather-icons",
//       name: "Feather Icons",
//       component: FeatherIcons
//     }
//   ],
//   component: null
// };

const paymentSuccessRoutes = {
  id: 'PaymentSuccess',
  path: '/payment/success',
  icon: null,
  component: () => <h1>Success</h1>,
  children: null,
};

const paymentRejectedRoutes = {
  id: 'PaymentRejected',
  path: '/payment/rejected',
  icon: null,
  component: () => <h1>Rejected</h1>,
  children: null,
};

const adminOwnerRoutes = {
  id: 'AdminOwner',
  path: '/cuenta-administracion',
  icon: null,
  section: 'adminOwnerAccount',
  component: OwnerInfo,
  children: null,
};

// const Test = () => {
// 	let { path, url } = useRouteMatch();
// 	console.log({ path, url });
// 	return (
// 		<>
// 			<h1>test</h1>
// 			<Switch>
// 				<Route exact path={`${path}/test`}>
// 					<h3>Please select topic</h3>
// 				</Route>
// 			</Switch>
// 		</>
// 	);
// };

// const test = {
// 	id: 'Test',
// 	path: '/test/:id',
// 	icon: null,
// 	exact: false,
// 	component: () => <Test />,
// };

const analyticsRoutes = {
  id: 'Analítica',
  path: '/analytics',
  icon: <ChartIcon />,
  component: Analytics,
  exact: false,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  // test,

  /* DASHBOARD */
  dashboardsRoutes,

  /* CONTACTS */
  createContactRoutes,
  contactsRoutes,
  profileContactRoutes,
  updateContactRoutes,

  /* CONDOS */
  createCondoRoutes,
  condosRoutes,
  profileCondoRoutes,
  updateCondoRoutes,

  /* PROPERTIES */
  propertiesRoutes,
  createPropertyRoutes,
  updatePropertyRoutes,
  profilePropertyRoutes,

  /* LEASES */
  leasesRoutes,
  createLeaseRoutes,
  updateLeaseRoutes,
  createLeaseConditionRoutes,
  profileLeaseRoutes,

  /* CONTRACTS */
  contractRoutes,
  updateContractRoute,

  /* ACCOUNT */
  createAccountRoutes,
  accountUsersRoutes,
  profileRoutes,
  profileMembersRoutes,
  updateMembersRoutes,

  /*TRANSACTIONS*/
  updateTransactionRoutes,
  profileTransactionRoutes,

  //corretajesRoutes,
  //mantencionesRoutes,
  // accountingRoutes,

  // leasseRoutes,
  presentationRoutes,
  privateRoutes,
  paymentSuccessRoutes,
  paymentRejectedRoutes,
  ownerAccountingRoutesOwner,
  basicServicesRoutes,
  adminOwnerRoutes,

  // Analytics
  analyticsRoutes,
];

export const dashboardLayoutRoutesOwner = [
  /* DASHBOARD */
  presentationRoutesOwner,
  dashboardsRoutesOwner,

  /* PROPERTIES */
  propertiesRoutes,
  profilePropertyRoutes,

  /* LEASES */
  profileLeaseRoutes,

  accountingRoutesOwner,

  profileOwnerRoutes,
];

// Routes using the Dashboard layout
export const dashboardLayoutWithoutAccountRoutes = [
  /* ACCOUNT */
  createAccountRoutes,
  profileRoutes,

  /* DASHBOARD */
  dashboardsRoutes,
  presentationRoutesWithoutAccount,
  newAccountRoutes,
  privateRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutes,
  contactsRoutes,
  condosRoutes,
  propertiesRoutes,
  leasesRoutes,
  // accountingRoutes,
  ownerAccountingRoutesOwner,
  // leasseRoutes,
  contractRoutes,
  basicServicesRoutes,
  analyticsRoutes,
];

export const sidebarRoutesOwner = [
  dashboardsRoutes,
  propertiesRoutes,
  // leasesRoutes,
  accountingRoutesOwner,
  profileOwnerRoutes,
];

export const routesByOwner = [
  dashboardsRoutes,
  propertiesRoutes,
  // leasesRoutes,
  accountingRoutesOwner,
  profileOwnerRoutes,
];

// Routes visible in the sidebar
export const sidebarRoutesWithoutAccount = [];
