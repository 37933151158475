import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import GetApp from '@material-ui/icons/GetApp';

import { GREEN, WHITE } from 'theme/variants';

const DEFAULT_STYLE = {
	backgroundColor: GREEN,
	color: WHITE,
	padding: '6px 30px',
	fontWeight: 'bold',
	borderRadius: 8,
};

export const exportToCSV = ({ csvData, fileName }) => {
	const fileType =
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const fileExtension = '.xlsx';

	const ws = XLSX.utils.json_to_sheet(csvData);
	const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
	const data = new Blob([excelBuffer], { type: fileType });
	FileSaver.saveAs(data, fileName + fileExtension);
};

export const XlsButton = ({
	onClick,
	tooltipTitle = 'Excel',
	style = DEFAULT_STYLE,
	title = 'XLS',
	loading,
}) => (
	<Tooltip title={tooltipTitle}>
		<Button disabled={loading} style={style} onClick={onClick}>
			{loading ? 'Generando...' : title} <GetApp />
		</Button>
	</Tooltip>
);

const ExportCSV = ({
	title = 'XLS',
	csvData,
	fileName,
	style = DEFAULT_STYLE,
	tooltipTitle = 'Excel',
	loading = false,
}) => (
	<XlsButton
		loading={loading}
		tooltipTitle={tooltipTitle}
		style={style}
		title={title}
		onClick={() => exportToCSV({ csvData, fileName })}
	/>
);

export default ExportCSV;
