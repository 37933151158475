import React from 'react';
import { useTypographyStyles } from './styles';
import { Typography as MuiTypography } from '@material-ui/core';

const Typography = ({ children, ...props }) => {
	const classes = useTypographyStyles(props);

	return (
		<MuiTypography
			{...props}
			color='initial'
			className={props.className + ' ' + classes.typography}
		>
			{children}
		</MuiTypography>
	);
};

export default Typography;
