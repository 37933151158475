import { makeStyles } from '@material-ui/core';

const getPadding = (padding) => {
	switch (padding) {
		case 'xs':
			return `5px 10px`;
		case 'md':
			return `8px 30px`;
		case 'lg':
			return `12px 60px`;
		case 'xl':
			return `15px 80px`;
		default:
			return;
	}
};

export const useButtonStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: (props) => theme.palette[props.color]?.main,
		color: (props) => theme.palette[props.color]?.contrastText,
		'&:hover': {
			backgroundColor: (props) => theme.palette[props.color]?.light,
		},
		padding: (props) => getPadding(props.padding),
		border: 'none',
		borderRadius: '8px',
		transition: 'ease-in-out 200ms',
	},
}));

export const useIconButtonStyle = makeStyles((theme) => ({
	root: {
		backgroundColor: (props) => theme.palette[props.color]?.main,
		color: (props) => theme.palette[props.color]?.contrastText,
		'&:hover': {
			backgroundColor: (props) => theme.palette[props.color]?.light,
		},
		padding: '15px 15px',
		transition: 'ease-in-out 200ms',
	},
}));
