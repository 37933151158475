import { makeStyles, lighten } from '@material-ui/core/styles';

export const useToolbarStyles = makeStyles(theme => ({
	root: {
		justifyContent: 'space-between',
		marginBottom: 10,
		backgroundColor: theme.palette.common.BACKGROUND_COLOR,
		padding: 0,
		margin: 0,
	},
	highlight: {
		color: theme.palette.secondary.main,
		backgroundColor: lighten(theme.palette.secondary.light, 0.85),
	},
	multilineColor: {
		backgroundColor: '#ffffff',
		borderRadius: 8,
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
			color: theme.palette.common.TEXT_COLOR,
			fontWeight: 'bold',
		},
		'&.Mui-focused': {
			'& .MuiOutlinedInput-notchedOutline': {
				border: 'none',
				color: theme.palette.common.TEXT_COLOR,
				fontWeight: 'bold',
			},
		},
	},
	container: {
		backgroundColor: '#ffffff',
		padding: '6px',
		borderRadius: 8,
	},
	icon: {
		color: theme.palette.common.TEXT_COLOR,
	},
	typography: {
		color: theme.palette.common.TEXT_COLOR,
		marginLeft: 5,
		fontWeight: 'bold',
	},
	tableRow: {
		'&:hover': {
			cursor: 'pointer',
		},
	},
	selectCheckbox: {
		zIndex: 9999999999,
	},
}));

export const useTableStyles = makeStyles(theme => ({
	table: {
		borderRadius: 10,
		backgroundColor: '#ffffff',
	},
	paper: {
		width: '100%',
		backgroundColor: '#ffffff',
	},
	container: {
		width: '100%',
		backgroundColor: '#ffffff',
	},
	linkButton: {
		borderRadius: 50,
	},
	paginator: {
		backgroundColor: 'white',
		marginTop: 10,
		borderRadius: 10,
	},
	loaderContainer: {
		height: 100,
		width: '100%',
	},
	sticky: {
		position: 'sticky',
		right: 0,
		background: '#ffffff',
	},
}));
