// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:0620eca4-02fc-4904-a2b0-72ce0fec4d4c",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_U5hvrRA0P",
    "aws_user_pools_web_client_id": "4m5netp1g87pbh445r4fh4uk17",
    "oauth": {},
    "aws_user_files_s3_bucket": "rentappstorage-rentapp",
    "aws_user_files_s3_bucket_region": "us-west-2",

    "aws_appsync_graphqlEndpoint": "https://rldp4faet5dptet3yh2izaogpy.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"  
};


export default awsmobile;
