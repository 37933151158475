import React, { useState, useContext } from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Link, useLocation } from 'react-router-dom';
import StateContext from '../services/contextService';
import {
	Avatar,
	Divider,
	Grid,
	Hidden,
	MenuItem,
	AppBar as MuiAppBar,
	IconButton as MuiIconButton,
	Toolbar,
	Typography,
	Popover,
	MenuList,
	ListItemIcon,
} from '@material-ui/core';
import moment from 'moment';
import gql from 'graphql-tag';
import { Menu as MenuIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useQuery } from '@apollo/client';
import urls from 'routes/urls';
import { useHistory } from 'react-router-dom';
import { client } from 'App';
import { parseUfValue } from 'globalFunctions/globalFunctions';

import { roles } from 'constants/common';

const getRoleLabel = role => {
	switch (role) {
		case roles.admin.SUPER:
			return 'super admin';
		case roles.admin.ACCOUNT:
			return 'admin';
		case roles.COLLABORATOR:
			return 'colaborador';
		case roles.EXECUTIVE_COLLABORATOR:
			return 'colaborador ejecutivo';
		case roles.GUEST:
			return 'invitado';
		case roles.OWNER:
			return 'propietario';

		default:
			break;
	}
};

export const GET_UF_VALUE = gql`
	query getUfByDate($input: GetUFByDateInput!) {
		getUfByDate(input: $input)
	}
`;

const AppBar = styled(MuiAppBar)`
	background: ${props => props.theme.header.background};
	color: ${props => props.theme.header.color};
	box-shadow: ${props => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
	svg {
		width: 22px;
		height: 22px;
	}
`;

const useStyles = makeStyles(theme => ({
	container: {
		margin: 15,
	},
	purple: {
		color: theme.palette.common.WHITE,
		backgroundColor: theme.palette.common.PURPLE,
	},
	menuAvatar: {
		color: theme.palette.common.WHITE,
		backgroundColor: theme.palette.common.PURPLE,
		'&:hover': {
			cursor: 'pointer',
		},
	},
}));

const MenuUserHeader = ({ avatarName, userName, role }) => {
	const classes = useStyles();
	return (
		<Grid
			container
			spacing={2}
			alignItems="center"
			className={classes.container}
		>
			<Grid item>
				<Avatar className={classes.purple}>{avatarName}</Avatar>
			</Grid>
			<Grid item>
				<Typography variant="body2">{userName}</Typography>
				<Typography variant="caption">{getRoleLabel(role)}</Typography>
			</Grid>
		</Grid>
	);
};

function UserMenu(props) {
	const { contextStates, setContextStates } = useContext(StateContext);
	const [anchorMenu, setAnchorMenu] = useState(null);
	const classes = useStyles();
	const history = useHistory();

	const toggleMenu = event => {
		setAnchorMenu(event.currentTarget);
	};

	const closeMenu = async () => {
		setAnchorMenu(null);
	};
	const currentUser = contextStates?.currentUser;
	const fullName =
		currentUser?.attributes?.given_name && currentUser?.attributes?.family_name
			? `${currentUser?.attributes?.given_name} ${currentUser?.attributes?.family_name}`
			: contextStates.accountName;
	const avatarName = fullName?.match(/\b(\w)/g)?.join('');
	const { pathname } = useLocation();

	const items = [
		{
			url: urls.PROFILE,
			title: 'Perfil',
			visible: true,
			selected: pathname === urls.PROFILE,
		},
		{
			url: urls.MEMBERS,
			title: 'Usuarios',
			visible: true,
			selected: pathname === urls.MEMBERS,
		},
		{
			url: urls.OWNER_PROFILE,
			title: 'Configuración',
			visible: true,
			selected: pathname === urls.OWNER_PROFILE,
		},
	];

	return (
		<React.Fragment>
			<Avatar onClick={toggleMenu} className={classes.menuAvatar}>
				{avatarName}
			</Avatar>
			<Popover
				id="menu-appbar"
				anchorEl={anchorMenu}
				open={Boolean(anchorMenu)}
				onClose={closeMenu}
			>
				<MenuList component="nav">
					<MenuUserHeader
						avatarName={avatarName}
						userName={fullName}
						role={contextStates.role}
					/>
					<Divider />
					{items.map(
						item =>
							item.visible && (
								<Link
									key={item.url}
									to={item.url}
									style={{ textDecoration: 'none', color: 'black' }}
								>
									<MenuItem selected={item.selected}>{item.title}</MenuItem>
								</Link>
							)
					)}
					<Divider />
					<MenuItem
						onClick={async () => {
							history.push('/');
							await Auth.signOut();
							setContextStates({});
							client.cache.reset();
							contextStates.onStateChange('signIn');
						}}
					>
						<ListItemIcon>
							<ExitToAppIcon />
						</ListItemIcon>
						<Typography variant="inherit">Cerrar sesión</Typography>
					</MenuItem>
				</MenuList>
			</Popover>
		</React.Fragment>
	);
}

const now = new Date();
const day = now.getDay();
const date = now.getDate();
const month = now.getMonth();
const year = now.getFullYear();

const getDay = day => {
	switch (day) {
		case 0:
			return 'Domingo';
		case 1:
			return 'Lunes';
		case 2:
			return 'Martes';
		case 3:
			return 'Miercoles';
		case 4:
			return 'Jueves';
		case 5:
			return 'Viernes';
		case 6:
			return 'Sabado';

		default:
			break;
	}
};

const getMonth = month => {
	switch (month) {
		case 1:
			return 'Enero';
		case 2:
			return 'Febrero';
		case 3:
			return 'Marzo';
		case 4:
			return 'Abril';
		case 5:
			return 'Mayo';
		case 6:
			return 'Junio';
		case 7:
			return 'Julio';
		case 8:
			return 'Agosto';
		case 9:
			return 'Septiembre';
		case 10:
			return 'Octubre';
		case 11:
			return 'Noviembre';
		case 12:
			return 'Diciembre';

		default:
			break;
	}
};

const addPrefix = number => (number <= 9 ? `0${number}` : number);

const Header = ({ onDrawerToggle }) => {
	const { contextStates } = useContext(StateContext);

	const fullDate = `${addPrefix(date)}-${addPrefix(month + 1)}-${year}`;
	const accountingPeriod = `${addPrefix(month + 1)}-${year}`;

	return (
		<React.Fragment>
			<AppBar position="sticky" elevation={0}>
				<Toolbar>
					<Grid container alignItems="center">
						<Hidden mdUp>
							<Grid item>
								<IconButton
									color="inherit"
									aria-label="Open drawer"
									onClick={onDrawerToggle}
								>
									<MenuIcon />
								</IconButton>
							</Grid>
						</Hidden>
						<Grid item>
							<Typography variant="h3" gutterBottom display="inline">
								{contextStates.accountName}
							</Typography>
						</Grid>
						<Grid item xs />
						<Grid item>
							<Grid container spacing={4} alignItems="center">
								<Grid item>
									<Typography variant="body1" display="inline">
										{fullDate}
									</Typography>
								</Grid>
								<UfSection />
								<Grid item>|</Grid>
								<Grid item>
									<Typography variant="body1" display="inline">
										Periodo Vigente: {accountingPeriod}
									</Typography>
								</Grid>
								<Grid item>
									<UserMenu />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
		</React.Fragment>
	);
};

const UfSection = () => {
	const { data, loading, error } = useQuery(GET_UF_VALUE, {
		variables: { input: { date: moment().format('yyyy-MM-DD') } },
	});

	if (error || loading) return null;

	return (
		<>
			<Grid item>|</Grid>
			<Grid item>
				<Typography variant="body1" display="inline">
					Valor UF: {parseUfValue(data?.getUfByDate, ',.2f')}
				</Typography>
			</Grid>
		</>
	);
};

export default connect()(withTheme(Header));
