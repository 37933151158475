import React, { useState, useEffect, useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useHistory } from 'react-router-dom';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Footer from '../components/Footer';

import { spacing } from '@material-ui/system';
import {
	Hidden,
	CssBaseline,
	Paper as MuiPaper,
	withWidth,
} from '@material-ui/core';

import { isWidthUp } from '@material-ui/core/withWidth';
import StateContext from 'services/contextService';
import Plausible from 'plausible-tracker';

const drawerWidth = 260;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
	display: flex;
	min-height: 100vh;
`;

const Drawer = styled.div`
	${props => props.theme.breakpoints.up('md')} {
		width: ${drawerWidth}px;
		flex-shrink: 0;
	}
`;

const AppContent = styled.div`
	flex: 1;
	flex-direction: column;
	display: flex;
	width: calc(100% - ${drawerWidth}px);
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
	flex: 1;
	background: ${props => props.theme.body.background};

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		flex: none;
	}

	.MuiPaper-root .MuiPaper-root {
		box-shadow: none;
	}
`;

const Dashboard = ({ children, routes, width, onStateChange }) => {
	const { trackEvent } = Plausible();
	const { contextStates } = useContext(StateContext);
	const history = useHistory();
	const [mobileOpen, setMobileOpen] = useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	useEffect(() => {
		trackEvent('userActions', {
			props: {
				accountId: contextStates.accountId,
				account: contextStates.accountName,
				email: contextStates.memberEmail,
				url: history.location.pathname,
			},
		});
	}, [history]);

	return (
		<Root>
			<CssBaseline />
			<GlobalStyle />
			<Drawer>
				<Hidden mdUp implementation="js">
					<Sidebar
						routes={routes}
						PaperProps={{ style: { width: drawerWidth } }}
						variant="temporary"
						open={mobileOpen}
						onClose={handleDrawerToggle}
					/>
				</Hidden>
				<Hidden smDown implementation="css">
					<Sidebar
						routes={routes}
						PaperProps={{ style: { width: drawerWidth } }}
					/>
				</Hidden>
			</Drawer>
			<AppContent>
				<Header
					onDrawerToggle={handleDrawerToggle}
					onStateChange={onStateChange}
				/>
				<MainContent p={isWidthUp('lg', width) ? 10 : 5}>
					{children}
				</MainContent>
				<Footer />
			</AppContent>
		</Root>
	);
};

export default withWidth()(Dashboard);
