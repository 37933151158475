import { permissions } from 'constants/permissions';

export const managePermissions = (roleName, key) => {
	const role = permissions.find(el => el.role === roleName);

	const result = key.split('.').reduce((p, prop) => {
		return p[prop];
	}, role);

	if (result === undefined) {
		return false;
	}

	return result;
};

const makePermissionArray = data => {
	if (!data) return null;
	let permissionsArray = [];
	Object.keys(data).forEach(key => {
		if (data[key]) {
			permissionsArray.push(key);
		}
	});

	return permissionsArray;
};

export const manageSectionActions = ({ role, section, actions }) => {
	if (!section || !actions) return true;

	const filteredPermissions = permissions.find(
		el => el.role === role
	)?.permissions;

	if (filteredPermissions) {
		return actions.every(value =>
			makePermissionArray(filteredPermissions[section])?.includes(value)
		);
	}

	return false;
};
