import React, { useContext } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import StateContext from '../../services/contextService';

export const GET_ACCOUNTS = gql`
  query listAccounts($input: ListAccountsInput!) {
    listAccounts(input: $input) {
      accountId
      name
      plan
      analyticUrl
    }
  }
`;

const SelectAccount = ({ width }) => {
  const { contextStates, setContextStates } = useContext(StateContext);

  const { data, loading } = useQuery(GET_ACCOUNTS, {
    variables: {
      input: { email: contextStates.memberEmail },
    },
  });

  const options = data?.listAccounts.map(({ name, accountId }) => ({
    value: accountId,
    label: name,
  }));

  const handleChange = e => {
    const { value } = e.target;
    const account = data?.listAccounts.find(
      ({ accountId }) => value === accountId
    );
    setContextStates({
      ...contextStates,
      accountId: account?.accountId,
      accountName: account?.name,
      analyticUrl: account?.analyticUrl,
    });
  };

  return (
    <Select
      value={contextStates.accountId ? contextStates.accountId : ''}
      onChange={handleChange}
      variant='outlined'
      disabled={loading}
      fullWidth={width ? false : true}
      style={{ backgroundColor: '#ffffff', margin: 10, width: width }}
    >
      {options?.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectAccount;
