import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Link, Typography, Grid, Breadcrumbs } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/core/styles';

const RaBreadcrumbs = ({title, rutes, handleBackBotton}) => {
    const classes = useStyles();

    const NavLink = React.forwardRef((props, ref) => (
        <RouterNavLink className={classes.root} innerRef={ref} {...props} />
    ));

    return (
        <Grid item xs={12} className={classes.root}>
            <div className={classes.contain}>
                {
                    handleBackBotton &&
                    <IconButton  
                    aria-label="Volver"
                    onClick={handleBackBotton}
                    >
                        <ArrowBackIosIcon/>
                    </IconButton >
                }
            </div>
            <div className={classes.contain}>
                <Typography variant="h3" gutterBottom display="inline">
                    {title}
                </Typography>

                {rutes && 
                    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                        {rutes.map((el, key) => 
                            <Link component={NavLink} exact to={el.rute} key={key}>
                                {el.name}
                            </Link>
                        )}
                        <Typography>{title}</Typography>
                    </Breadcrumbs>
                }
            </div>
        </Grid>
    )
}

const useStyles = makeStyles({
    root: {
        display: 'flex',
        margin: '0px 0px 1rem 0px'
    },
    contain: {
        width: 'fit-content',
        maxWidth: 'fit-content',
    }
});

export default RaBreadcrumbs;