import React, { useState } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { StylesProvider } from '@material-ui/styles';
import { ThemeProvider } from 'styled-components';
import maTheme from './theme';
import Routes from './routes/Routes';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from 'apollo-link';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { Authenticator } from 'aws-amplify-react';

import SignIn from './authentication/SignIn/SignIn';
import SignUp from './authentication/SignUp';
import ConfirmSignUp from './authentication/ConfirmSignUp';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createHttpLink } from 'apollo-link-http';
import { Auth } from '@aws-amplify/auth';
import { Storage } from '@aws-amplify/storage';
import API from '@aws-amplify/api';
import StateContext from 'services/contextService';
import { ForgotPassword } from './authentication/recovery-password/ForgotPassword';
import { setContext } from '@apollo/client/link/context';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

Amplify.configure(awsconfig);
Auth.configure(awsconfig);
API.configure(awsconfig);
Storage.configure({ level: 'private' });

const region = 'us-west-2';

const auth = {
	type: 'AMAZON_COGNITO_USER_POOLS',
	jwtToken: async () =>
		(await Auth.currentSession()).getAccessToken().getJwtToken(),
};

const urlApiComunicaciones =
	'https://iqnzvz7k5jhtrbnui7wy6qbmlq.appsync-api.us-west-2.amazonaws.com/graphql';

const urlApiContrato =
	'https://r6cgp45ehfaxdiyxyyptumbsx4.appsync-api.us-west-2.amazonaws.com/graphql';

const QA_URL = 'https://k3i6scdiu0.execute-api.us-east-1.amazonaws.com/qa';
const DEV_URL = 'http://ec2-52-45-86-170.compute-1.amazonaws.com/graphiql/';
const LOCAL_URL = 'http://127.0.0.1:8000/graphiql/';
const PROD_URL = 'https://wi1ur87b4l.execute-api.us-west-2.amazonaws.com/prd';

const getUrlByStage = () => {
	switch (process.env.REACT_APP_STAGE) {
		case 'test':
			return QA_URL;
		case 'prod':
			return PROD_URL;
		case 'dev':
			return DEV_URL;
		case 'local':
			return LOCAL_URL;
		default:
			break;
	}
};

const httpLink = new createUploadLink({
	uri: getUrlByStage(),
	credentials: 'include',
});

// Se agrega Context
const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem('usertoken');
	if (
		localStorage.getItem('usertoken') !== undefined &&
		localStorage.getItem('usertoken')
	) {
	} else {
		Auth.signOut();
		client.cache.reset();
	}
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		},
	};
});

const comunicacionesLink = ApolloLink.from([
	createAuthLink({ urlApiComunicaciones, region, auth }),
	createHttpLink({ uri: urlApiComunicaciones }),
]);

const contratosLink = ApolloLink.from([
	createAuthLink({ urlApiContrato, region, auth }),
	createHttpLink({ uri: urlApiContrato }),
]);

const otherLinks = ApolloLink.split(
	operation => operation.getContext().clientName === 'comunicacionesLink',
	comunicacionesLink,
	authLink.concat(httpLink)
);
export const client = new ApolloClient({
	link: ApolloLink.split(
		operation => operation.getContext().clientName === 'contratosLink',
		contratosLink,
		otherLinks
	),
	cache: new InMemoryCache({
		typePolicies: {
			Property: {
				keyFields: ['propertyId'],
			},
		},
	}),
});

function App({ theme }) {
	const [contextStates, setContextStates] = useState({});
	const [global_snackbar, setGlobalSnackbar] = useState({
		opened: false,
		message: '',
		severity: '',
	});

	const _resetGlobalSnackbar = (_, reason) => {
		if (reason === 'clickaway') return;
		setGlobalSnackbar({ ...global_snackbar, opened: false });
	};

	const contextValue = { contextStates, setContextStates };

	return (
		<React.Fragment>
			<Helmet titleTemplate="%s | Rentapp" defaultTitle="Rentapp" />
			<StylesProvider injectFirst>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<MuiThemeProvider theme={maTheme[theme.currentTheme]}>
						<ThemeProvider theme={maTheme[theme.currentTheme]}>
							<ApolloProvider client={client}>
								<StateContext.Provider value={contextValue}>
									<Authenticator hideDefault={true} authState="signIn">
										<SignIn
											override={'signIn'}
											global_snackbar={global_snackbar}
											resetGlobalSnackbar={_resetGlobalSnackbar}
										/>
										<ForgotPassword override={'forgotPassword'} />
										<SignUp override={'signUp'} />
										<ConfirmSignUp setGlobalSnackbar={setGlobalSnackbar} />
										<Routes />
										<ToastContainer
											position="top-right"
											autoClose={3000}
											hideProgressBar={false}
											newestOnTop={false}
											closeOnClick
											rtl={false}
											pauseOnFocusLoss
											draggable={false}
											pauseOnHover
											theme="colored"
										/>
									</Authenticator>
								</StateContext.Provider>
							</ApolloProvider>
						</ThemeProvider>
					</MuiThemeProvider>
				</MuiPickersUtilsProvider>
			</StylesProvider>
		</React.Fragment>
	);
}

export default connect(store => ({ theme: store.themeReducer }))(App);
