// eslint-disable-next-line
import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Loading from '../loading/Loading';
import ReactCodeInput from 'react-code-input';
import { spacing } from "@material-ui/system";
import styled from "styled-components";

const Spacer = styled.div(spacing);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    reactCodeInput: {
        display: "flex !important",
        justifycontent: "space-around"
    },
}));

const ConfirmSignUp = (props) => {

    const [loading, setLoading] = useState(false)
    const [snackbar_message, setSnackbarMessage] = useState('')
    const [snackbar_opened, setSnackbarOpened] = useState(false)
    const [confirmation_code, setConfirmationCode] = useState('')
    const classes = useStyles();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpened(false);
    };

    const _resetStates = () => {
        if (snackbar_opened) setSnackbarOpened(false)
        if (confirmation_code !== '') setConfirmationCode('')
    }

    const _handleOnClick = async () => {

        setLoading(true)
        try {
            // After retrieving the confirmation code from the user
            await Auth.confirmSignUp(
                props.authData.email,
                confirmation_code
            )
            props.onStateChange('signIn', {})
            props.setGlobalSnackbar({
                opened: true,
                message: 'Cuenta creada correctamente.',
                severity: 'success'
            })
            _resetStates()
        }
        catch (error) {
            if (error.code === 'CodeMismatchException') {
                setSnackbarMessage('El código ingresado no es válido, por favor intenta de nuevo.');
                setSnackbarOpened(true);
            }
            else if (error.message === 'Confirmation code cannot be empty') {
                setSnackbarMessage('El código de confirmación no puede estar vacío.');
                setSnackbarOpened(true);
            }
            else if (error.code === 'NetworkError') {
                setSnackbarMessage('Problemas de conexión.');
                setSnackbarOpened(true);
            }
            else {
                setSnackbarMessage('Ha habido un error con la verificación del email, por favor intenta de nuevo.');
                setSnackbarOpened(true);
            }
        }
        setLoading(false)
    }

    const classInput = {
        className: classes.reactCodeInput,
        inputStyle: {
            fontFamily: 'monospace',
            borderRadius: '6px',
            border: '1px solid lightgrey',
            boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 10px 0px',
            margin: '4px',
            paddingLeft: '8px',
            width: '50px',
            height: '50px',
            fontSize: '32px',
            boxSizing: 'border-box',
            color: 'black',
            backgroundColor: 'white',
        },
    }

    return (
        <React.Fragment>

            {/* only render this component when the authState is 'signUp' */}
            {props.authState === 'confirmSignUp' &&
                <Grid container style={{ height: '100vh' }}>
                    <Grid className={classes.paper} container
                        direction="column"
                        justify="center"
                        alignItems="center">

                        {loading && <Loading />}

                        <Grid container item xs={12} md={6} lg={4} xl={3} justify="center" alignItems="center">
                            <Spacer mb={10}>
                                <Grid container item justify="center" alignItems="center" className={classes.paper} >
                                    <Avatar className={classes.avatar}>
                                        <LockOutlinedIcon />
                                    </Avatar>
                                    <Typography component="h1" variant="h5">
                                        Confirmación de Registro
                                    </Typography>
                                </Grid>
                            </ Spacer>
                            <form className={classes.form} noValidate>
                                <ReactCodeInput
                                    {...classInput}
                                    name="Código de confirmación"
                                    type='number'
                                    fields={6}
                                    onChange={(code) => setConfirmationCode(code)}
                                />
                                <Typography component="h1" variant="caption" align="center">
                                    Ingrese el código enviado a su email
                                </Typography>
                                <Spacer mb={5} />
                                <Button
                                    //type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={() => _handleOnClick()}
                                >
                                    Continuar
                                </Button>
                                <Grid container justify="flex-end">
                                    <Grid item>
                                        <Link
                                            href="#"
                                            onClick={() => {
                                                props.onStateChange('signIn', {})
                                                _resetStates()
                                            }}
                                            variant="body2">
                                            ¿Ya tienes una cuenta? Ingresar
                                        </Link>
                                    </Grid>
                                </Grid>
                            </form>
                            <Snackbar open={snackbar_opened} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="error">
                                    {snackbar_message}
                                </Alert>
                            </Snackbar>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}

export default ConfirmSignUp