import gql from 'graphql-tag';

export const queryUF = gql`
  query UF($date: String!) {
    uf(date: $date) {
      value
      date
    }
  }
`;

export const LIST_ACCOUNTS = gql`
  query Accounts($email: String!) {
    allAccounts(email: $email) {
      role
      name
      accountId
      memberEmail
    }
  }
`;

export const LIST_ACCOUNTS_NEW = gql`
  query listAccountsNew($input: ListAccountsNewInput!) {
    listAccountsNew(input: $input) {
      memberInfo {
        memberEmail
        role
        givenName
        familyName
      }
      accountInfo {
        analyticUrl
        name
        plan
        email
        country
        street
        number
        city
        region
        comuna
        communicationEmail
        accountId
      }
    }
  }
`;
