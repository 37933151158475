import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(() => ({
	cell: {
		// color: theme.palette.common.TEXT_COLOR,
		fontWeight: 600,
		fontSize: '12px',
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	sticky: { position: 'sticky', right: 0, background: '#ffffff' },
}));

const EnhancedTableHead = ({
	onSelectAllClick,
	numSelected,
	rowCount,
	columns,
	loading,
	isAllSelected,
	isVisibleCheckAll,
	order,
	orderBy,
	onRequestSort,
}) => {
	const classes = useStyles();
	const hasData = rowCount > 0;

	const createSortHandler = property => event => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{hasData &&
				!loading &&
				typeof numSelected === 'number' &&
				onSelectAllClick &&
				isVisibleCheckAll ? (
					<TableCell padding="checkbox">
						<Checkbox
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={isAllSelected}
							onChange={onSelectAllClick}
							inputProps={{ 'aria-label': 'select all desserts' }}
						/>
					</TableCell>
				) : null}

				{columns.map(({ field, align = 'left', title }, index) => (
					<TableCell
						key={index}
						className={classes.cell}
						align={align}
						sortDirection={orderBy === columns.field ? order : false}
					>
						<TableSortLabel
							active={orderBy === field}
							direction={orderBy === field ? order : 'asc'}
							onClick={createSortHandler(field)}
						>
							{title}
							{orderBy === field ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
};

export default EnhancedTableHead;
