import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import Loading from '../../loading/Loading';
import MuiAlert from '@material-ui/lab/Alert';
import {
	Grid,
	Hidden,
	Typography,
	Snackbar,
	TextField,
	Checkbox,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import Button from 'components/Button/Button';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import logo from '../../fondo_portada.png';
import logoColor from '../../fondo_portada.png';
import logoWhite from '../../rentappLogoWhite.png';

import styled from 'styled-components';
import { URL_TOKEN } from '../../constants/token';

const Spacer = styled.div(spacing);

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}
async function token(user) {
	await fetch(URL_TOKEN, {
		method: 'POST',
		body: JSON.stringify({
			email: user.attributes.email,
			contrasena: user.attributes.family_name,
		}),
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		},
	})
		.then(response => response.json())
		.then(data => {
			localStorage.setItem('usertoken', data.token);
		});
}
export default function SignIn(props) {
	const [loading, setLoading] = useState(false);
	const [snackbar_message, setSnackbarMessage] = useState('');
	const [snackbar_opened, setSnackbarOpened] = useState(false);
	const [email, setEmail] = useState('');
	const [clave, setClave] = useState('');
	const classes = useStyles();

	const handleClose = (_, reason) => {
		if (reason === 'clickaway') return;
		setSnackbarOpened(false);
	};

	const _handleOnClick = async () => {
		setLoading(true);
		try {
			await Auth.signIn({ username: email, password: clave });

			const user = await Auth.currentAuthenticatedUser();
			//=========Se agrega nuevo token
			await token(user);
			//
			props.onStateChange('signedIn', { attributes: user.attributes });
			setEmail('');
			setClave('');
		} catch (error) {
			if (
				error.message ===
				'The username should either be a string or one of the sign in types'
			) {
				setSnackbarMessage('Por favor ingresa el email.');
				setSnackbarOpened(true);
			} else if (
				error.message ===
				'Custom auth lambda trigger is not configured for the user pool.'
			) {
				setSnackbarMessage('Por favor ingresa la contraseña.');
				setSnackbarOpened(true);
			} else if (error.message === 'User does not exist.') {
				setSnackbarMessage('El usuario no existe.');
				setSnackbarOpened(true);
			} else if (error.message === 'User is not confirmed.') {
				setSnackbarMessage('Usuario registrado, pero no confirmado.');
				setSnackbarOpened(true);
			} else if (error.message === 'Incorrect username or password.') {
				setSnackbarMessage('La contraseña no es correcta.');
				setSnackbarOpened(true);
			} else if (error.code === 'NetworkError') {
				setSnackbarMessage('Problemas de conexión a internet.');
				setSnackbarOpened(true);
			} else if (error.message === 'Password attempts exceeded') {
				setSnackbarMessage('Password attempts exceeded.');
				setSnackbarOpened(true);
			} else {
				setSnackbarMessage('Ha habido un error al intentar iniciar sesión.');
				setSnackbarOpened(true);
			}
		}
		setLoading(false);
	};

	const handleKeyEnter = event => {
		var code = event.keyCode || event.which;
		if (code === 13) {
			_handleOnClick();
		}
	};

	if (props.authState === 'signIn')
		return (
			<Grid container alignItems="center" justify="center">
				<Grid item xs={12} md={12} lg={6}>
					{loading && <Loading />}
					<Grid container alignItems="center" justify="center">
						<Grid item xs={10} md={8} lg={8}>
							<Grid item>
								<SignInHeader classes={classes} />
							</Grid>
							<SignInForm
								classes={classes}
								setEmail={setEmail}
								handleKeyEnter={handleKeyEnter}
								setClave={setClave}
								onStateChange={props.onStateChange}
								handleOnClick={_handleOnClick}
								setSnackbarOpened={setSnackbarOpened}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Hidden mdDown>
					<Grid item lg={6} className={classes.logoContainer}>
						<img
							src={logo}
							alt="RentAPP"
							width="100%"
							height="100%"
							style={{ position: 'absolute' }}
						/>
						<img
							src={logoWhite}
							alt="RentAPP"
							style={{
								zIndex: 1,
								width: '60%',
							}}
						/>
					</Grid>
				</Hidden>

				<Snackbar
					open={props.global_snackbar.opened}
					autoHideDuration={6000}
					onClose={() => props.resetGlobalSnackbar()}
				>
					<Alert
						role="alert"
						onClose={() => props.resetGlobalSnackbar()}
						severity={props.global_snackbar.severity}
					>
						{props.global_snackbar.message}
					</Alert>
				</Snackbar>
				<Snackbar
					open={snackbar_opened}
					autoHideDuration={6000}
					onClose={handleClose}
				>
					<Alert role="alert" onClose={handleClose} severity="error">
						{snackbar_message}
					</Alert>
				</Snackbar>
			</Grid>
		);
	else return null;
}

const SignInHeader = () => (
	<Grid container justify="center" alignItems="center">
		<Hidden lgUp>
			<Grid
				container
				item
				direction="row"
				justify="center"
				alignItems="center"
				xs={9}
				sm={9}
				md={5}
				width="100%"
				max-height="25%"
			>
				<Spacer mb={10}>
					<img src={logoColor} alt="Logo RentAPP" width="100%" height="100%" />
				</Spacer>
			</Grid>
		</Hidden>

		<Grid container direction="row" justify="center" alignItems="center">
			<Typography
				component="div"
				variant="h4"
				style={{ fontWeight: 'bold', margin: 20 }}
			>
				INICIAR SESIÓN
			</Typography>
		</Grid>
	</Grid>
);

const SignInForm = props => (
	<Grid container direction="column" justify="center">
		<TextField
			id="email"
			variant="outlined"
			margin="normal"
			required
			name="email"
			label="Usuario (Email)"
			autoComplete="email"
			fullWidth
			autoFocus
			onChange={event => props.setEmail(event.target.value)}
			onKeyPress={props.handleKeyEnter}
			size="small"
		/>
		<TextField
			id="password"
			variant="outlined"
			margin="normal"
			required
			fullWidth
			name="password"
			label="Contraseña"
			type="password"
			autoComplete="current-password"
			onChange={event => props.setClave(event.target.value)}
			onKeyPress={props.handleKeyEnter}
			size="small"
		/>
		<FormControlLabel
			control={<Checkbox name="remember" />}
			label="Recordarme"
		/>
		<Grid
			container
			alignItems="center"
			justify="space-between"
			style={{ marginTop: 15 }}
		>
			<Button
				// variant='link'
				onClick={() => props.onStateChange('forgotPassword', {})}
			>
				<Typography>¿Olvidaste la contraseña?</Typography>
			</Button>
			<Button
				alt="Ingresar"
				color="primary"
				onClick={() => props.handleOnClick()}
			>
				Iniciar Sesión{' '}
			</Button>
		</Grid>
	</Grid>
);

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	logoContainer: {
		display: 'flex',
		height: '100vh',
		position: 'relative',
		justifyContent: 'center',
		alignItems: 'center',
	},
	link: {
		color: theme.secondary,
		'&:hover': {
			cursor: 'pointer',
		},
		marginLeft: 5,
		fontWeight: 'bold',
	},
}));
