import React from 'react';
import styled from 'styled-components';

import { CircularProgress } from '@material-ui/core';

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  padding: 0.25rem;
`;

function Loader({ size = '1.5rem' }) {
  return (
    <Root>
      <CircularProgress m={2} color='secondary' size={size} />
    </Root>
  );
}

export default Loader;
