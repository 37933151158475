import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Loading from '../loading/Loading';
import { spacing } from '@material-ui/system';
import styled from 'styled-components';
import { ModalConfirm } from 'components';

const Spacer = styled.div(spacing);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SignUp(props) {
  const [loading, setLoading] = useState(false);
  const [snackbar_message, setSnackbarMessage] = useState('');
  const [snackbar_opened, setSnackbarOpened] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  const [usuario, setUsuario] = useState({
    nombre: '',
    apellido: '',
    email: '',
    verificacion_email: '',
    clave: '',
    verificacion_clave: '',
  });

  const [is_valido, setIsValido] = useState({
    nombre: false,
    apellido: false,
    email: false,
    verificacion_email: false,
    clave: false,
    verificacion_clave: false,
  });

  const [show_validaciones, setShowValidaciones] = useState({
    nombre: false,
    apellido: false,
    email: false,
    verificacion_email: false,
    clave: false,
    verificacion_clave: false,
  });

  useEffect(() => {
    Object.keys(is_valido).forEach((key) => {
      if (is_valido[key] && show_validaciones[key]) {
        setShowValidaciones((show_validaciones) => ({
          ...show_validaciones,
          [key]: false,
        }));
      }
    });
  }, [is_valido, show_validaciones]);

  useEffect(() => {
    if (usuario.nombre.length > 3 && !is_valido.nombre)
      setIsValido((is_valido) => ({ ...is_valido, nombre: true }));
    else if (usuario.nombre.length <= 3 && is_valido.nombre)
      setIsValido((is_valido) => ({ ...is_valido, nombre: false }));
  }, [usuario.nombre, is_valido.nombre]);

  useEffect(() => {
    if (usuario.apellido.length > 3 && !is_valido.apellido)
      setIsValido((is_valido) => ({ ...is_valido, apellido: true }));
    else if (usuario.apellido.length <= 3 && is_valido.apellido)
      setIsValido((is_valido) => ({ ...is_valido, apellido: false }));
  }, [usuario.apellido, is_valido.apellido]);

  useEffect(() => {
    const mail_format = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const valid_email = usuario.email.match(mail_format) ? true : false;
    const valid_verificacion_email =
      usuario.email === usuario.verificacion_email ? true : false;

    if (valid_email && valid_verificacion_email)
      setIsValido((is_valido) => ({
        ...is_valido,
        email: true,
        verificacion_email: true,
      }));
    if (valid_email && !valid_verificacion_email)
      setIsValido((is_valido) => ({
        ...is_valido,
        email: true,
        verificacion_email: false,
      }));
    if (!valid_email && valid_verificacion_email)
      setIsValido((is_valido) => ({
        ...is_valido,
        email: false,
        verificacion_email: true,
      }));
    if (!valid_email && !valid_verificacion_email)
      setIsValido((is_valido) => ({
        ...is_valido,
        email: false,
        verificacion_email: false,
      }));
  }, [usuario.email, usuario.verificacion_email]);

  useEffect(() => {
    if (
      usuario.verificacion_email === usuario.email &&
      !is_valido.verificacion_email
    )
      setIsValido((is_valido) => ({ ...is_valido, verificacion_email: true }));
    else if (
      usuario.verificacion_email !== usuario.email &&
      is_valido.verificacion_email
    )
      setIsValido((is_valido) => ({ ...is_valido, verificacion_email: false }));
  }, [usuario.verificacion_email, usuario.email, is_valido.verificacion_email]);

  useEffect(() => {
    const clave_valida = usuario.clave.length >= 6 ? true : false;
    const verificacion_clave_valida =
      usuario.clave === usuario.verificacion_clave ? true : false;

    if (clave_valida && verificacion_clave_valida)
      setIsValido((is_valido) => ({
        ...is_valido,
        clave: true,
        verificacion_clave: true,
      }));
    if (clave_valida && !verificacion_clave_valida)
      setIsValido((is_valido) => ({
        ...is_valido,
        clave: true,
        verificacion_clave: false,
      }));
    if (!clave_valida && verificacion_clave_valida)
      setIsValido((is_valido) => ({
        ...is_valido,
        clave: false,
        verificacion_clave: true,
      }));
    if (!clave_valida && !verificacion_clave_valida)
      setIsValido((is_valido) => ({
        ...is_valido,
        clave: false,
        verificacion_clave: false,
      }));
  }, [usuario.clave, usuario.verificacion_clave]);

  useEffect(() => {
    if (
      usuario.verificacion_clave === usuario.clave &&
      !is_valido.verificacion_clave
    )
      setIsValido((is_valido) => ({ ...is_valido, verificacion_clave: true }));
    else if (
      usuario.verificacion_clave !== usuario.clave &&
      is_valido.verificacion_clave
    )
      setIsValido((is_valido) => ({ ...is_valido, verificacion_clave: false }));
  }, [usuario.verificacion_clave, is_valido.verificacion_clave, usuario.clave]);

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpened(false);
  };

  const _setShowValidaciones = () => {
    let show_validaciones_aux = {};

    Object.keys(is_valido).forEach((key) => {
      show_validaciones_aux[key] = true;
      if (is_valido[key]) show_validaciones_aux[key] = false;
    });
    setShowValidaciones(show_validaciones_aux);
  };

  const _resetStates = () => {
    if (snackbar_opened) setSnackbarOpened(false);
    setUsuario({
      nombre: '',
      apellido: '',
      email: '',
      verificacion_email: '',
      clave: '',
      verificacion_clave: '',
    });
    setIsValido({
      nombre: false,
      apellido: false,
      email: false,
      verificacion_email: false,
      clave: false,
      verificacion_clave: true,
    });
    setShowValidaciones({
      nombre: false,
      apellido: false,
      email: false,
      verificacion_email: false,
      clave: false,
      verificacion_clave: false,
    });
  };

  const _handleOnClick = async () => {
    setLoading(true);
    try {
      await Auth.signUp({
        username: usuario.email,
        password: usuario.clave,
        attributes: {
          email: usuario.email,
          given_name: usuario.nombre,
          family_name: usuario.apellido,
        },
      });
      setVisibleModal(true);
    } catch (error) {
      if (error.code === 'UsernameExistsException') {
        setSnackbarMessage('Ya existe una cuenta con el email ingresado.');
        setSnackbarOpened(true);
      } else if (error.code === 'NetworkError') {
        setSnackbarMessage('Problemas de conexión a internet.');
        setSnackbarOpened(true);
      } else {
        setSnackbarMessage(
          'Ha habido un error al intentar registrar al usuario.'
        );
        setSnackbarOpened(true);
      }
      console.log('error: ', error);
    }

    setLoading(false);
  };

  const classes = useStyles();

  if (loading) return <Loading />;
  return (
    <React.Fragment>
      {props.authState === 'signUp' && (
        <Grid container style={{ height: '100vh' }}>
          <Grid
            className={classes.paper}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid
              container
              item
              xs={12}
              md={8}
              lg={4}
              justify="center"
              alignItems="center"
            >
              <Spacer mb={10}>
                <Grid
                  container
                  item
                  justify="center"
                  alignItems="center"
                  className={classes.paper}
                >
                  <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Registro de Usuario
                  </Typography>
                </Grid>
              </Spacer>
              <form className={classes.form} noValidate>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={show_validaciones.nombre ? true : false}
                      helperText={
                        show_validaciones.nombre ? 'Ingresa tu nombre' : null
                      }
                      variant="outlined"
                      autoComplete="fname"
                      name="firstName"
                      value={usuario.nombre}
                      required
                      fullWidth
                      id="firstName"
                      label="Nombre"
                      autoFocus
                      onChange={(event) =>
                        setUsuario({ ...usuario, nombre: event.target.value })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={show_validaciones.apellido ? true : false}
                      helperText={
                        show_validaciones.apellido
                          ? 'Ingresa tu apellido'
                          : null
                      }
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      value={usuario.apellido}
                      label="Apellido"
                      name="lastName"
                      autoComplete="lname"
                      onChange={(event) =>
                        setUsuario({ ...usuario, apellido: event.target.value })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={show_validaciones.email ? true : false}
                      helperText={
                        show_validaciones.email
                          ? 'Ingresa un email válido'
                          : null
                      }
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      value={usuario.email}
                      label="Email"
                      name="email"
                      autoComplete="email"
                      onChange={(event) =>
                        setUsuario({ ...usuario, email: event.target.value })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={
                        show_validaciones.verificacion_email ? true : false
                      }
                      helperText={
                        show_validaciones.verificacion_email
                          ? 'El email debe ser igual al ingresado'
                          : null
                      }
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Reingresa tu email"
                      name="email"
                      value={usuario.verificacion_email}
                      autoComplete="email"
                      onChange={(event) =>
                        setUsuario({
                          ...usuario,
                          verificacion_email: event.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={show_validaciones.clave ? true : false}
                      helperText={
                        show_validaciones.clave
                          ? 'La clave debe tener al menos 6 caracteres'
                          : null
                      }
                      variant="outlined"
                      required
                      fullWidth
                      name="password"
                      label="(Crear contraseña)"
                      value={usuario.clave}
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      onChange={(event) =>
                        setUsuario({ ...usuario, clave: event.target.value })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={
                        show_validaciones.verificacion_clave ? true : false
                      }
                      helperText={
                        show_validaciones.verificacion_clave
                          ? 'La clave debe ser igual a la ingresada'
                          : null
                      }
                      variant="outlined"
                      required
                      fullWidth
                      name="password"
                      label="Repetir contraseña"
                      type="password"
                      id="password"
                      value={usuario.verificacion_clave}
                      autoComplete="current-password"
                      onChange={(event) =>
                        setUsuario({
                          ...usuario,
                          verificacion_clave: event.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => {
                    const all_valid = Object.values(is_valido).every(Boolean);

                    if (all_valid) _handleOnClick();
                    else _setShowValidaciones();
                  }}
                >
                  Registrarse
                </Button>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Link
                      href="#"
                      onClick={() => {
                        props.onStateChange('signIn', {});
                        _resetStates();
                      }}
                      variant="body2"
                    >
                      ¿Ya tienes una cuenta? Ingresar
                    </Link>
                  </Grid>
                </Grid>
              </form>
              <Snackbar
                open={snackbar_opened}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error">
                  {snackbar_message}
                </Alert>
              </Snackbar>
              <ModalConfirm
                isVisible={visibleModal}
                onClose={() => {
                  _resetStates();
                  setVisibleModal(false);
                }}
                title="Registro exitoso"
                withEmail={false}
                onOk={() => props.onStateChange('signIn')}
                okText="Ir a Login"
                onCancelText="Cerrar"
                contentText="Se ha enviado un link de confirmación al correo ingresado"
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
