import { roles } from 'constants/common';

export const permissions = [
	{
		role: roles.admin.RENTAPP,
		permissions: {
			adminOwnerAccount: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			adminUsers: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			contacts: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			condominios: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			propiedades: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			arriendos: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			contabilidad: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			manageFiles: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
		},
	},
	{
		role: roles.admin.ACCOUNT,
		permissions: {
			adminOwnerAccount: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			adminUsers: {
				crear: 0,
				ver: 0,
				editar: 0,
				borrar: 0,
			},
			contacts: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			condominios: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			propiedades: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			arriendos: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			contabilidad: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			manageFiles: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
		},
	},
	{
		role: roles.admin.SUPER,
		permissions: {
			adminOwnerAccount: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			adminUsers: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			contacts: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			condominios: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			propiedades: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			arriendos: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			contabilidad: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			manageFiles: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
		},
	},
	{
		role: roles.admin.ADMIN,
		permissions: {
			adminOwnerAccount: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 0,
			},
			adminUsers: {
				crear: 0,
				ver: 0,
				editar: 0,
				borrar: 0,
			},
			contacts: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			condominios: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			propiedades: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			arriendos: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			contabilidad: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			manageFiles: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
		},
	},
	{
		role: roles.COLLABORATOR,
		permissions: {
			adminOwnerAccount: {
				crear: 0,
				ver: 0,
				editar: 0,
				borrar: 0,
			},
			adminUsers: {
				crear: 0,
				ver: 0,
				editar: 0,
				borrar: 0,
			},
			contacts: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 0,
			},
			condominios: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 0,
			},
			propiedades: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 0,
			},
			arriendos: {
				crear: 0,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
			contabilidad: {
				crear: 0,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
			manageFiles: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
		},
	},
	{
		role: roles.EXECUTIVE_COLLABORATOR,
		permissions: {
			adminOwnerAccount: {
				crear: 0,
				ver: 0,
				editar: 0,
				borrar: 0,
			},
			adminUsers: {
				crear: 0,
				ver: 0,
				editar: 0,
				borrar: 0,
			},
			contacts: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 0,
			},
			condominios: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 0,
			},
			propiedades: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 0,
			},
			arriendos: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 0,
			},
			contabilidad: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			manageFiles: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
		},
	},
	{
		role: roles.GUEST,
		permissions: {
			adminOwnerAccount: {
				crear: 0,
				ver: 0,
				editar: 0,
				borrar: 0,
			},
			adminUsers: {
				crear: 0,
				ver: 0,
				editar: 0,
				borrar: 0,
			},
			contacts: {
				crear: 0,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
			condominios: {
				crear: 0,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
			propiedades: {
				crear: 0,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
			arriendos: {
				crear: 0,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
			contabilidad: {
				crear: 0,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
			manageFiles: {
				crear: 0,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
		},
	},
	{
		role: roles.OWNER,
		permissions: {
			adminOwnerAccount: {
				crear: 0,
				ver: 0,
				editar: 0,
				borrar: 0,
			},
			adminUsers: {
				crear: 0,
				ver: 0,
				editar: 0,
				borrar: 0,
			},
			contacts: {
				crear: 0,
				ver: 0,
				editar: 0,
				borrar: 0,
			},
			condominios: {
				crear: 0,
				ver: 0,
				editar: 0,
				borrar: 0,
			},
			propiedades: {
				crear: 0,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
			arriendos: {
				crear: 0,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
			contabilidad: {
				crear: 0,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
			manageFiles: {
				crear: 0,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
		},
	},
	{
		role: roles.OWNER_EXECUTIVE,
		permissions: {
			adminOwnerAccount: {
				crear: 0,
				ver: 0,
				editar: 0,
				borrar: 0,
			},
			adminUsers: {
				crear: 0,
				ver: 0,
				editar: 0,
				borrar: 0,
			},
			contacts: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			condominios: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			propiedades: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			arriendos: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			contabilidad: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			manageFiles: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
		},
	},
	{
		role: roles.LEASE_EXECUTIVE,
		permissions: {
			adminOwnerAccount: {
				crear: 0,
				ver: 0,
				editar: 0,
				borrar: 0,
			},
			adminUsers: {
				crear: 0,
				ver: 0,
				editar: 0,
				borrar: 0,
			},
			contacts: {
				crear: 1,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
			condominios: {
				crear: 1,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
			propiedades: {
				crear: 0,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
			arriendos: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			contabilidad: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
			manageFiles: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
		},
	},
	{
		role: roles.ASSISTANT_EXECUTIVE,
		permissions: {
			adminOwnerAccount: {
				crear: 0,
				ver: 0,
				editar: 0,
				borrar: 0,
			},
			adminUsers: {
				crear: 0,
				ver: 0,
				editar: 0,
				borrar: 0,
			},
			contacts: {
				crear: 0,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
			condominios: {
				crear: 0,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
			propiedades: {
				crear: 0,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
			arriendos: {
				crear: 0,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
			contabilidad: {
				crear: 1,
				ver: 1,
				editar: 0,
				borrar: 0,
			},
			manageFiles: {
				crear: 1,
				ver: 1,
				editar: 1,
				borrar: 1,
			},
		},
	},
];
