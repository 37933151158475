import React from 'react';
import PropTypes from 'prop-types';
import { AppBar as MuiAppBar, Tab, Tabs } from '@material-ui/core';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

const AppBar = styled(MuiAppBar)(spacing);
const Spacer = styled.div(spacing);

const TabsHeader = ({ tabs = [], onChange, defaultValue = 0, selectedTab }) => (
	<Spacer mb={5}>
		<AppBar position="static" color="inherit">
			<Tabs
				defaultValue={defaultValue}
				variant="fullWidth"
				centered
				value={selectedTab.value}
				onChange={onChange}
				indicatorColor="primary"
				textColor="primary"
			>
				{tabs.map(tab => (
					<Tab
						variant="fullWidth"
						key={tab.key}
						value={tab.value}
						label={tab.label.toUpperCase()}
					/>
				))}
			</Tabs>
		</AppBar>
	</Spacer>
);

TabsHeader.propTypes = {
	tabs: PropTypes.array,
};

export default TabsHeader;
