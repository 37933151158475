import React, { useState } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Button, Menu, MenuItem } from '@material-ui/core';

const MenuActions = ({ title, actions }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleOpenClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenuItem = (onClick) => {
		setAnchorEl(null);
		onClick();
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button
				onClick={handleOpenClick}
				variant="outlined"
				endIcon={<MoreVertIcon />}
				id="basic-button"
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
			>
				{title}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleCloseMenu}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{actions.map((item) => (
					<MenuItem
						disabled={!item.visible}
						key={item.ID}
						onClick={() => handleCloseMenuItem(item.onClick)}
					>
						{item.TITLE}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default MenuActions;
