import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';

// {
// 	"CodeDeliveryDetails": {
// 			"AttributeName": "email",
// 			"DeliveryMedium": "EMAIL",
// 			"Destination": "j***@g***"
// 	}
// }

export const handleSubmitRecovery = async ({ email = '' }) => {
	try {
		const resp = await Auth.forgotPassword(email);

		return !!resp.CodeDeliveryDetails.Destination;

		// console.log('Lo que DEVUELVE => ', respA);
	} catch (error) {
		if (error.code === 'InvalidParameterException') {
			Swal.fire('No existe el usuario ingresado', '', 'error');
		}
		console.log(error);
	}
};
