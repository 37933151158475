import { permissions } from 'constants/permissions';

export const managePermissions = (roleName, key) => {
	const role = permissions.find(el => el.role === roleName);

	const result = key.split('.').reduce((p, prop) => {
		return p[prop];
	}, role);

	if (result === undefined) {
		return false;
	}

	return result;
};

export const manageSectionActions = ({ role, section, actions }) => {
	if (!section) return true;

	const filteredPermissions = permissions.find(
		el => el.role === role
	).permissions;

	return actions.every(value =>
		Object.keys(filteredPermissions[section])
			.map(key => filteredPermissions[key])
			.includes(value)
	);
};
