/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import {
	Box,
	Button,
	Card,
	Grid,
	Link,
	TextField,
	Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { handleSubmitRecovery } from '../functions/handleSubmitRecovery';

function initialValues() {
	return {
		email: '',
	};
}

function validationSchema() {
	return {
		email: Yup.string().required('El email es requerido').trim(),
	};
}

export const GetCodeForm = props => {
	const formik = useFormik({
		initialValues: initialValues(),
		onSubmit: ({ email }, { resetForm }) => {
			props.setEmail(email);
			handleSubmitRecovery({ email });
			props.setGmailWasSent(true);
			resetForm();
		},
		validationSchema: Yup.object(validationSchema()),
	});
	return (
		<Box component="form" noValidate>
			<Card style={{ p: { xs: 4, md: 6 } }}>
				<Grid
					container
					spacing={4}
					style={{ padding: '50px', paddingTop: '0' }}
				>
					<Grid item xs={12} style={{ padding: '50px' }}>
						<Typography variant={'subtitle2'} style={{ marginBottom: 2 }}>
							Ingrese su correo electrónico
						</Typography>
						<TextField
							id="email"
							variant="outlined"
							margin="normal"
							required
							name="email"
							type="email"
							label="Usuario (Email)"
							autoComplete="email"
							fullWidth
							autoFocus
							onChange={formik.handleChange}
							value={formik.values.email}
							error={!formik.errors?.email && !!formik.errors?.email}
							size="small"
						/>
					</Grid>
					<Grid item container xs={12}>
						<Box
							display="flex"
							flexDirection={{ xs: 'column', sm: 'row' }}
							alignItems={{ xs: 'stretched', sm: 'center' }}
							justifyContent={'space-between'}
							width={1}
							maxWidth={600}
							margin={'0 auto'}
						>
							<Box marginBottom={{ xs: 1, sm: 0 }}>
								<Button
									size={'large'}
									variant={'outlined'}
									onClick={props.onGoBack}
									fullWidth
								>
									Volver al inicio
								</Button>
							</Box>
							<Button
								size={'large'}
								variant={'contained'}
								onClick={() => formik.handleSubmit()}
							>
								Enviar código para cambiar la contraseña
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Card>
		</Box>
	);
};
