import React from 'react'
import { Card } from '@material-ui/core'
import './CustomCard.css'

const CustomCard = ({children}) => {

    return (
        <Card className='custom-card'>
            {children}
        </Card>
    )
}

export default CustomCard