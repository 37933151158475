import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Snackbar,
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import Loader from './Loader';

const ModalConfirm = ({
  isVisible,
  onClose,
  title,
  onOk,
  okText = 'Confirmar',
  contentText,
  loading,
  onCloseAlert,
  alert,
  onEmailChange,
  sendEmail,
  withEmail = true,
  message,
  secondText,
  autoHideDuration = 3000,
  onCancelText = 'Cancelar',
}) => (
  <>
    <Dialog open={isVisible} onClose={onClose} maxWidth="md">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <Grid item xs={12}>
            {loading ? (
              <>
                <Loader />
                <Typography variant="subtitle1">{message}</Typography>
              </>
            ) : (
              <>
                <Typography variant="subtitle1">{contentText}</Typography>
                <Typography variant="subtitle2">{secondText}</Typography>
              </>
            )}
          </Grid>
          {withEmail && !loading ? (
            <Grid item xs={12} md={6}>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onEmailChange}
                      checked={sendEmail}
                      name="email"
                    />
                  }
                  label="¿Enviar correo?"
                />
              </FormControl>
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} color="secondary" onClick={onClose}>
          {onCancelText}
        </Button>
        <Button disabled={loading} color="primary" onClick={onOk}>
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
    {alert && (
      <Snackbar
        open={alert.open}
        autoHideDuration={autoHideDuration}
        onClose={onCloseAlert}
      >
        <MuiAlert severity={alert.severity}>{alert.message}</MuiAlert>
      </Snackbar>
    )}
  </>
);

export default ModalConfirm;
