import React from 'react';
import * as d3 from 'd3';
import { Typography } from '@material-ui/core';
import { currencyType, entities, roles, labelRoles } from 'constants/common';
import MaskedInput from 'react-text-mask';
import Resizer from 'react-image-file-resizer';
import moment from 'moment';

const formatValue = value => {
	let aux = value;
	let aux2 = aux.replace('.', '*');
	let aux3 = aux2.replaceAll(',', '.');
	let aux4 = aux3.replace('*', ',');
	return aux4;
};

export function formatd3(tipo, valor) {
	const format_entero = ',.0f';
	const format_decimal = ',.2f';
	let format = '.1f';
	if (valor === '' || valor === null || !tipo) {
		return '-';
	}
	if (tipo === 'entero') {
		format = d3.format(format_entero);
	}
	if (tipo === currencyType.UF) {
		format = d3.format(format_decimal);
		let copyValue = formatValue(format(valor >= 0 ? valor : valor * -1));

		return valor >= 0 ? `UF ${copyValue}` : `-UF ${copyValue}`;
	}
	if (tipo === currencyType.CLP || tipo === 'IPC') {
		format = d3.format(format_entero);
		let copyValue = formatValue(format(valor >= 0 ? valor : valor * -1));

		return valor >= 0 ? `$${copyValue}` : `-$${copyValue}`;
	}

	return formatValue(format(valor));
}

export const parseUfValue = (value, decimalFormat = ',.0f') => {
	const format_decimal = decimalFormat;
	let format = '.1f';
	if (value === '' || value === null) {
		return '-';
	}

	format = d3.format(format_decimal);
	let copyValue = formatValue(format(value >= 0 ? value : value * -1));

	return value >= 0 ? `$ ${copyValue}` : `-$ ${copyValue}`;
};

export function ParseToDate(string) {
	const date = string.split('-');

	return new Date(date[0], date[1] - 1, date[2]);
}

export const formatDate = date => date && moment(date).format('DD-MM-YYYY');
export const formatAccountingPeriod = date =>
	date && moment(date).format('MM-YYYY');

export function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			{/* <Link color="inherit" href="https://material-ui.com/"> */}
			Rentapp
			{/* </Link> */} {new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

export const removeAccents = str =>
	str ? str.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '';

export const parseRole = role => {
	switch (role.toLowerCase()) {
		case roles.OWNER:
			return labelRoles[roles.OWNER];
		case roles.admin.SUPER:
			return labelRoles[roles.admin.SUPER];
		case roles.admin.RENTAPP:
			return labelRoles[roles.admin.RENTAPP];
		case roles.admin.ACCOUNT:
			return labelRoles[roles.admin.ACCOUNT];
		case roles.admin.ADMIN:
			return labelRoles[roles.admin.ADMIN];
		case roles.LEASE:
			return labelRoles[roles.LEASE];
		case roles.EXECUTIVE_COLLABORATOR:
			return labelRoles[roles.EXECUTIVE_COLLABORATOR];
		case roles.COLLABORATOR:
			return labelRoles[roles.COLLABORATOR];
		case roles.GUEST:
			return labelRoles[roles.GUEST];
		default:
			break;
	}
};

export function isEmpty(value) {
	const type = typeof value;

	switch (type) {
		case 'string':
			return !value || value.length === 0;
		case 'array':
			return !value || value.length === 0;
		case 'object':
			return !value || Object.keys(value).length === 0;
		default:
			break;
	}
}

export const sortAlphabetically = (arr, key) =>
	arr.sort((a, b) => {
		const labelA = a[key].toUpperCase();
		const labelB = b[key].toUpperCase();
		return labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
	});

export function PhoneMask(props) {
	const { inputRef, ...other } = props;

	return (
		<MaskedInput
			{...other}
			ref={ref => {
				inputRef(ref ? ref.inputElement : null);
			}}
			guide={false}
			mask={[/[0-9]/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
		/>
	);
}

export function parseCLP(amount) {
	if (amount === '' || amount === null) {
		return '';
	}
	let copyAmount = amount;
	copyAmount = copyAmount < 0 ? copyAmount * -1 : copyAmount;
	const signalFormat = new Intl.NumberFormat('es-CL', {
		style: 'currency',
		currency: 'CLP',
	}).format(copyAmount ? copyAmount : 0);
	return amount >= 0 ? signalFormat : `-${signalFormat}`;
}

export const avoidInputChangeByScrolling = e => {
	e.target.blur();
};

export const blockInvalidCharacter = (e, aditionalCharacters) => {
	['e', 'E', '+', '-', '.', ...(aditionalCharacters ?? '')].includes(e.key) &&
		e.preventDefault();
};

export const allowOnlyNumbers = e => {
	const keycode = e.which || e.keycode;
	if (keycode === 188) return;
	keycode < 48 || (keycode > 57 && e.preventDefault());
};

export const clearInput = (formik, field) => {
	formik.values[field] == '0' && formik.setFieldValue(field, '');
};

export const setValueOnEmptyInput = (formik, field, value) => {
	formik.values[field] === '' && formik.setFieldValue(field, value);
};

export const getDifferenceInDays = (date1, date2) => {
	const d1 = new Date(date1);
	const d2 = new Date(date2);
	const difference = d2.getTime() - d1.getTime();
	const differenceInDays = difference / (1000 * 60 * 60 * 24);
	return Math.abs(differenceInDays);
};

export const checkSameMonthDates = (date1, date2) => {
	const d1 = new Date(date1);
	const d2 = new Date(date2);
	const d1Month = d1.getMonth();
	const d2Month = d2.getMonth();
	const d1Year = d1.getYear();
	const d2Year = d2.getYear();
	return d1Month === d2Month && d1Year === d2Year;
};

export const resizeFile = (file, imageToResizeWidth, imageToResizeHeight) => {
	const resizeAspect = 0.5;
	const resizeQuality = 100;

	return new Promise(resolve => {
		Resizer.imageFileResizer(
			file,
			imageToResizeWidth * resizeAspect,
			imageToResizeHeight * resizeAspect,
			'JPEG',
			resizeQuality,
			0,
			uri => {
				resolve(uri);
			},
			'file'
		);
	});
};

export const fuzzy = (arr, s) => {
	let hay = removeAccents(arr).toLowerCase(),
		i = 0,
		n = -1,
		l;
	s = removeAccents(s).toLowerCase();
	for (; (l = s[i++]); ) if (!~(n = hay.indexOf(l, n + 1))) return false;
	return true;
};
