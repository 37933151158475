/* eslint-disable react/react-in-jsx-scope */
import React, { useState } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { SetCodeForm } from './SetCodeForm';
import { GetCodeForm } from './GetCodeForm';

export const ForgotPassword = props => {
	const [gmailWasSent, setGmailWasSent] = useState(false);
	const [email, setEmail] = useState('');

	const hadleGoBack = () => props.onStateChange('signIn', {});
	if (props.authState === 'forgotPassword') {
		return (
			<>
				<Box bgcolor={'alternate.main'}>
					<Container style={{ padding: '50px', marginTop: '30px' }}>
						<Box marginBottom={4}>
							<Typography
								style={{
									textTransform: 'uppercase',
									fontSize: '2em',
									fontWeight: 'bold',
									color: '#412f83',
								}}
								gutterBottom
								// color={'text.secondary'}
							>
								Recuperación de contraseña
							</Typography>
							<Typography
								// variant='h4'
								style={{
									fontWeight: 700,
									fontSize: '1.4em',
								}}
							>
								¿Ha olvidado su contraseña?
							</Typography>
							<Typography>
								Ingrese su Email en el recuadro y le enviaremos un código para
								que pueda cambiar su contraseña
							</Typography>
						</Box>
						<Box bgcolor={'alternate.main'}>
							<Container>
								<GetCodeForm
									setEmail={setEmail}
									setGmailWasSent={setGmailWasSent}
									onGoBack={hadleGoBack}
								/>
							</Container>
							{gmailWasSent && (
								<Container style={{ marginTop: '20px' }}>
									<SetCodeForm email={email} onGoBack={hadleGoBack} />
								</Container>
							)}
						</Box>
					</Container>
				</Box>
			</>
		);
	} else return null;
};
