// eslint-disable-next-line
import React, { useState } from 'react';
import {Dialog, DialogContent, Grid, CircularProgress, Typography }  from '@material-ui/core';


export default function(props) {
  
    return (
        <Dialog aria-labelledby="loading-dialog" open={true}>
            <DialogContent>
                <Grid container style={{textAlign: 'center', padding: '32px'}}>
                    <Grid item xs={12}>
                        <CircularProgress/>
                    </Grid>
                    <Grid item xs={12}>  
                        <Typography variant='subtitle1'>{props.text}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>   
    );
}