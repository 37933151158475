import {
	blue,
	cyan,
	deepPurple,
	grey,
	indigo,
	teal,
} from '@material-ui/core/colors';

export const BACKGROUND_COLOR = '#fafafa';
// export const BACKGROUND_COLOR = '#f2f2f2';
// export const BACKGROUND_COLOR = '#f9f9f9';
export const TITLE_COLOR = '#0f233f';
export const TEXT_COLOR = '#acb8d8';
export const GREEN = '#44cf98';
export const LIGHT_GREEN = '#21e8af';
export const PURPLE = '#8025db';
export const SECONDARY = '#412f83';
export const PRIMARY = '#665ff6';
// export const PRIMARY = '#5576d1';
// export const WHITE = '#ffffff';
export const WHITE = '#fcfcfc';
export const MUSTARD = '#e9c46a';
export const ORANGE = '#e76f51';

const rentApp = {
	name: 'RentApp',
	palette: {
		primary: {
			superLight: '#f1f0f5',
			main: PRIMARY,
			contrastText: WHITE,
		},
		secondary: {
			main: SECONDARY,
			contrastText: WHITE,
		},
		lightGreen: {
			main: LIGHT_GREEN,
			contrastText: WHITE,
		},
		lightBlue: {
			superLight: '#FBFCFF',
			main: '#acb8d8',
			light: '#ECF1FF',
			dark: '#677087',
			contrastText: WHITE,
		},
		indigo: {
			main: indigo['A400'],
			contrastText: WHITE,
		},
		deepPurple: {
			superLight: '#f2f0f5',
			main: deepPurple[400],
			light: deepPurple[300],
			dark: deepPurple[500],
			contrastText: WHITE,
		},
		grey: {
			main: grey[500],
			light: grey[300],
			dark: grey[600],
			contrastText: WHITE,
		},
		text1: {
			superLight: grey[50],
			main: grey[800],
			light: grey[400],
			dark: grey[900],
			contrastText: WHITE,
		},
		text2: {
			superLight: '#e9ecf7',
			main: '#acb8d8',
			light: '#d5dced',
			dark: '#4a5678',
			contrastText: '#435073',
		},
		darkBlue: {
			main: '#0f233f',
			contrastText: WHITE,
		},
		strongPink: {
			main: '#ff007f',
			contrastText: WHITE,
		},
		cyan: {
			main: '#06aed5',
			superLight: '#fafeff',
			light: cyan[300],
			dark: cyan[600],
			contrastText: WHITE,
		},
		teal: {
			main: '#1affd5',
			light: teal[200],
			dark: teal[700],
			contrastText: WHITE,
		},
		orange: {
			main: '#F17F38',
			contrastText: WHITE,
		},
		common: {
			BACKGROUND_COLOR,
			TITLE_COLOR,
			TEXT_COLOR,
			GREEN,
			LIGHT_GREEN,
			PURPLE,
			SECONDARY,
			PRIMARY,
			WHITE,
			ORANGE,
			MUSTARD,
		},
	},
	header: {
		color: grey[500],
		background: WHITE,
		search: {
			color: grey[800],
		},
		indicator: {
			background: blue[600],
		},
	},
	sidebar: {
		color: WHITE,
		background: process.env.REACT_APP_STAGE === 'test' ? ORANGE : TITLE_COLOR,
		header: {
			color: TITLE_COLOR,
			background: process.env.REACT_APP_STAGE === 'test' ? ORANGE : TITLE_COLOR,
			brand: {
				color: WHITE,
			},
		},
		footer: {
			color: WHITE,
			background: process.env.REACT_APP_STAGE === 'test' ? ORANGE : TITLE_COLOR,
			online: {
				background: WHITE,
			},
		},
		category: {
			fontWeight: 400,
		},
		badge: {
			color: WHITE,
			background: PURPLE,
		},
	},
	body: {
		background: BACKGROUND_COLOR,
	},
};

const variants = [rentApp];

export default variants;
