import { makeStyles } from '@material-ui/styles';

export const useTooltipStyles = makeStyles(theme => ({
    tooltip: {
        fontWeight: '400',
        fontSize: '12px',
        borderRadius: '5px',
        backgroundColor: props => theme.palette[props.color]?.dark,
        color: props => theme.palette[props.color]?.contrastText,
    },
}));
