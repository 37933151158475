/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import {
	Box,
	Button,
	Card,
	Grid,
	Link,
	TextField,
	Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { handleSubmitSendCode } from '../functions/handleSubmitSendCode';

function initialValues() {
	return {
		code: '',
		newPassword: '',
	};
}

function validationSchema() {
	return {
		code: Yup.string().required('El email es requerido').trim(),
		newPassword: Yup.string().required('El email es requerido'),
	};
}

export const SetCodeForm = ({ email, onGoBack }) => {
	const formik = useFormik({
		initialValues: initialValues(),
		onSubmit: ({ code, newPassword }) => {
			// console.log(code, newPassword);
			handleSubmitSendCode({ email, code, newPassword });
		},
		validationSchema: Yup.object(validationSchema()),
	});

	return (
		<Card style={{ p: { xs: 4, md: 6 } }}>
			<Grid container spacing={4} style={{ padding: '50px' }}>
				<Grid item xs={6}>
					<Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
						Ingrese el código que le ha llegado a su correo
					</Typography>
					<TextField
						id="code"
						variant="outlined"
						margin="normal"
						required
						name="code"
						type="code"
						label="Código de verificación"
						autoFocus
						onChange={formik.handleChange}
						value={formik.values.code}
						error={!formik.errors?.code && !!formik.errors?.code}
						size="small"
						// helperText={errors.email}
						// onBlur={handleBlur}
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
						Ingrese el su nueva contraseña
					</Typography>
					<TextField
						id="newPassword"
						variant="outlined"
						margin="normal"
						required
						name="newPassword"
						type="password"
						label="Nueva contraseña"
						autoFocus
						onChange={formik.handleChange}
						value={formik.values.newPassword}
						error={!formik.errors?.newPassword && !!formik.errors?.newPassword}
						size="small"
						// helperText={errors.email}
						// onBlur={handleBlur}
					/>
				</Grid>
				<Grid item container xs={12}>
					<Box
						display="flex"
						flexDirection={{ xs: 'column', sm: 'row' }}
						alignItems={{ xs: 'stretched', sm: 'center' }}
						justifyContent={'space-between'}
						width={1}
						maxWidth={600}
						margin={'0 auto'}
					>
						<Box marginBottom={{ xs: 1, sm: 0 }}>
							<Button
								size={'large'}
								variant={'outlined'}
								onClick={onGoBack}
								fullWidth
							>
								Volver al inicio
							</Button>
						</Box>
						<Button
							size={'large'}
							variant={'contained'}
							onClick={() => formik.handleSubmit()}
						>
							Cambiar la contraseña
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Card>
	);
};
