import React from 'react';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import { useTooltipStyles } from './styles';

const Tooltip = ({ children, color = 'lightBlue', ...props }) => {
	const tooltipStyles = useTooltipStyles({ color });

	return (
		<MuiTooltip
			color='default'
			{...props}
			classes={tooltipStyles}
			TransitionProps={{ timeout: 200 }}
		>
			{children}
		</MuiTooltip>
	);
};

export default Tooltip;
