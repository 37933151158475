import React from 'react';
import { useButtonStyles, useIconButtonStyle } from './styles';
import { Button as MuiButton, IconButton } from '@material-ui/core';

const Button = ({ children, icon, ...props }) => {
	const buttonStyles = useButtonStyles(props);
	const iconButtonStyles = useIconButtonStyle(props);

	return (
		<React.Fragment>
			{icon ? (
				<IconButton {...props} color='default' classes={iconButtonStyles}>
					{icon}
				</IconButton>
			) : (
				<MuiButton {...props} color='default' classes={buttonStyles}>
					{children}
				</MuiButton>
			)}
		</React.Fragment>
	);
};

export default Button;
