const accountingStatus = {
	initial: {
		ID: 'porCobrar',
		LABEL: 'Por cobrar',
	},
	open: {
		ID: 'porCobrar',
		LABEL: 'Por cobrar',
	},
	charge: {
		ID: 'enCobro',
		LABEL: 'En cobro',
	},
	partialPayment: {
		ID: 'pagado',
		LABEL: 'En portal pago',
	},
	paidOut: {
		ID: 'porLiquidar',
		LABEL: 'Por Liquidar pago',
	},
	overPayment: {
		ID: 'sobrePago',
		LABEL: 'Por liquidar sobre pago',
	},
	liquidated: {
		ID: 'liquidado',
		LABEL: 'Liquidado',
	},
	close: {
		ID: 'cerrada',
		LABEL: 'Cerrada',
	},
	payeds: {
		ID: 'porLiquidar',
		LABEL: 'Pagado',
	},
};

const roles = {
	OWNER: 'propietario',
	admin: {
		SUPER: 'superadmin',
		RENTAPP: 'adminrentapp',
		ACCOUNT: 'admincuenta',
		ADMIN: 'administrador',
	},
	LEASE: 'arrendatario',
	EXECUTIVE_COLLABORATOR: 'executive_collaborator',
	COLLABORATOR: 'collaborator',
	GUEST: 'invitado',
	OWNER_EXECUTIVE: 'owner_executive',
	LEASE_EXECUTIVE: 'lease_executive',
	ASSISTANT_EXECUTIVE: 'assistant_executive',
};

const labelRoles = {
	[roles.admin.ADMIN]: 'Administrador',
	[roles.admin.ACCOUNT]: 'Administrador de Cuenta',
	[roles.OWNER]: 'Propietario',
	[roles.admin.SUPER]: 'Super Administrador',
	[roles.admin.RENTAPP]: 'Administrador Rentapp',
	[roles.COLLABORATOR]: 'Colaborador',
	[roles.EXECUTIVE_COLLABORATOR]: 'Ejecutivo(a) de Administración',
	[roles.OWNER_EXECUTIVE]: 'Ejecutivo(a) Propietario(a)',
	[roles.LEASE_EXECUTIVE]: 'Ejecutivo(a) Arrendatario(a)',
	[roles.ASSISTANT_EXECUTIVE]: 'Ejecutivo(a) Asistente',
	[roles.GUEST]: 'Invitado',
	[roles.LEASE]: 'Arrendatario',
};

const entities = {
	admin: {
		ID: roles.admin.ADMIN,
		LABEL: labelRoles.ADMIN,
	},
	owner: {
		ID: roles.OWNER,
		LABEL: labelRoles.OWNER,
	},
	lease: {
		ID: roles.LEASE,
		LABEL: labelRoles.LEASE,
	},
};

const constants = {
	DATE_FORMATE: 'yyyy-MM',
};

const CLP_CURRENCY = 'CLP';

const table = {
	ROWS_PER_PAGE_OPTIONS: [5, 10, 25, 50, 100],
	labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
	LABEL_ROWS_PER_PAGE: 'Items por página',
};

const transactionTypes = {
	income: {
		ID: 'income',
		LABEL: 'Ingresos',
	},
	expenses: {
		ID: 'expenses',
		LABEL: 'Egresos',
	},
};

const currencyType = {
	CLP: 'CLP',
	UF: 'UF',
	SV: 'S/V',
};

export const CURRENCY_OPTIONS = [
	{ value: currencyType.CLP, label: 'CLP' },
	{ value: currencyType.UF, label: 'UF' },
];

const accType = [
	{
		value: 'Cuenta Corriente',
		label: 'Cuenta Corriente',
	},
	{
		value: 'Cuenta Vista',
		label: 'Cuenta Vista',
	},
	{
		value: 'Cuenta de Ahorros',
		label: 'Cuenta de Ahorros',
	},
];

export {
	accType,
	accountingStatus,
	constants,
	CLP_CURRENCY,
	currencyType,
	entities,
	table,
	transactionTypes,
	roles,
	labelRoles,
};
