import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';

export const handleSubmitSendCode = async ({
	email = '',
	code = '',
	newPassword = '',
}) => {
	try {
		const res = await Auth.forgotPasswordSubmit(email, code, newPassword);
		console.log(res)
		if (res === 'SUCCESS' || res === undefined) {
			Swal.fire(
				'¡Felicidades!, tu contraseña se ha cambiado con éxito',
				'Ahora puedes ingresar nuevamente con tu nueva contraseña',
				'success'
			);
		}
	} catch (error) {
		console.error(error);
		return error;
	}
};
