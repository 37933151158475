import { makeStyles } from '@material-ui/core';

export const useSectionHeaderStyles = makeStyles({
	container: {
		paddingBottom: '2rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: '10px',
	},
	title: {
		paddingBottom: '5px',
		display: 'flex',
		alignItems: 'center',
	},
	subTitle: {
		marginTop: '7px',
		display: 'flex',
		alignItems: 'center',
		opacity: '.7',
	},
	buttons: {
		display: 'flex',
		gap: 10,
	},
});
