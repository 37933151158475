import React from 'react';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import { ExportCSV } from 'components';
import { removeAccents } from 'globalFunctions/globalFunctions';
import { useToolbarStyles } from './styles';

const EnhancedTableToolbar = ({
	numSelected,
	numLeases,
	actions,
	xls,
	onSearchChange,
	searchedValue,
	onClearSearch,
	title,
	searchbarPlaceholder,
	searchOptions,
}) => {
	const classes = useToolbarStyles();
	return (
		<Toolbar
			className={`${classes.root} ${numSelected > 0 && classes.highlight}`}
		>
			<Grid container spacing={4}>
				<Grid item>
					<div className={classes.container}>
						{numSelected > 0 ? (
							<Typography color="inherit" variant="subtitle1" component="div">
								{numSelected} seleccionados
							</Typography>
						) : (
							<Grid container>
								<Typography variant="h6" id="tableTitle" component="div">
									Total
								</Typography>
								<Typography
									variant="h6"
									id="tableTitle"
									component="div"
									className={classes.typography}
								>
									{numLeases > 0 ? title : '0 Encontrados'}
								</Typography>
							</Grid>
						)}
					</div>
				</Grid>
				<Grid item>
					<TextField
						onChange={e => onSearchChange(removeAccents(e.target.value))}
						variant="outlined"
						label=""
						placeholder={searchbarPlaceholder}
						size="small"
						value={searchedValue}
						className={classes.multilineColor}
						onKeyDown={searchOptions?.onKeyDownSearch}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									{searchedValue?.length > 0 ? (
										<IconButton size="small" onClick={onClearSearch}>
											<ClearIcon className={classes.icon} size="small" />
										</IconButton>
									) : (
										<SearchIcon className={classes.icon} />
									)}
								</InputAdornment>
							),
						}}
					/>
					{searchOptions && (
						<Button
							variant="outlined"
							color="primary"
							disabled={searchedValue?.length === 0}
							onClick={() => searchOptions.onSearch(searchedValue)}
							style={{ marginLeft: 5 }}
						>
							Buscar
						</Button>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={4} justify="flex-end">
				{actions?.map(({ visible, id, component }) =>
					visible ? (
						<Grid key={id} item>
							{component}
						</Grid>
					) : numSelected > 0 && visible === undefined ? (
						<Grid key={id} item>
							{component}
						</Grid>
					) : null
				)}
				{xls && numLeases > 0 ? (
					<Grid item>
						<ExportCSV csvData={xls.data} fileName={xls.title} />
					</Grid>
				) : null}
			</Grid>
		</Toolbar>
	);
};

export default EnhancedTableToolbar;
