const typography = {
	useNextVariants: true,
	fontFamily: ['Poppins', 'SemiBold'].join(','),
	// fontFamily: ['Barlow', 'SemiBold'].join(','),
	fontSize: 14,
	fontWeightLight: 300,
	fontWeightRegular: 400,
	fontWeightMedium: 600,
	allVariants: {
		color: '#677087',
	},
	h1: {
		fontSize: '36px',
		fontWeight: '800',
		// letterSpacing: '-2px',
	},
	h2: {
		fontSize: '1.75rem',
		fontWeight: 800,
		lineHeight: 1.2,
		// letterSpacing: '-0.05em',
	},
	h3: {
		fontSize: '1.5rem',
		fontWeight: 600,
		lineHeight: 1.2,
		// letterSpacing: '-0.04em',
	},
	h4: {
		fontSize: '1.25rem',
		fontWeight: 600,
		lineHeight: 1.2,
		// letterSpacing: '-0.04em',
	},
	h5: {
		fontSize: '1.0625rem',
		fontWeight: 600,
		lineHeight: 1.2,
		// letterSpacing: '-0.04em',
	},
	h6: {
		fontSize: '1rem',
		fontWeight: 600,
		lineHeight: 1.2,
		// letterSpacing: '-0.04em',
	},
	body1: {
		fontSize: 14,
		fontWeight: 500,
	},
	button: {
		textTransform: 'none',
	},
};

export default typography;
