import { makeStyles } from '@material-ui/core';

export const useChipStyles = makeStyles((theme) => ({
	root: {
		transition: 'ease-in-out 200ms',
		fontWeight: 600,
		padding: '15px 5px',
		backgroundColor: (props) => theme.palette[props.color]?.main,
		color: (props) => theme.palette[props.color]?.contrastText,
		'&:hover': {
			backgroundColor: (props) => theme.palette[props.hovercolor]?.main,
			color: (props) => theme.palette[props.hovercolor]?.contrastText,
			'& $icon': {
				// color: (props) => theme.palette[props.color]?.contrastText,
				color: 'white',
			},
		},
	},
	icon: {
		transition: 'ease-in-out 200ms',
		color: (props) => theme.palette[props.color]?.contrastText,
	},
}));
