import React from 'react';
import { Chip as MuiChip } from '@material-ui/core';
import { useChipStyles } from './styles';

const Chip = ({ ...props }) => {
	const chipStyles = useChipStyles(props);

	return <MuiChip color='default' classes={chipStyles} {...props} />;
};

export default Chip;
