import styled from 'styled-components';
import { Chip as MuiChip } from '@material-ui/core';

const Chip = styled(MuiChip)`
  background: ${(props) => props.background};
  color: ${(props) => props.theme.palette.common.white};
  padding: 15px 15px 15px 15px;
`;

export default Chip;
